import React, { useCallback, useEffect, useState } from 'react';
import { dateFn, utcTimeToLocalTime } from '../../../utils/dateTimeFormatter';
import Carousel from 'react-elastic-carousel';
import ClientButton from '../../../components/ClientButton';
import location from '../../../images/location.png';
import group from '../../../images/group.png';
import ProviderProfile from '../../../components/ProviderProfile';
import { isEmpty } from 'lodash';
import moment from 'moment';
import AccordionBasic from '../../../components/AccordionBasic';
import no_picture from '../../../images/no-profile-picture.png';
import AccordionProviderTimes from '../../../components/AccordionProviderTimes';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';
export default function SingleProvider({ provider, orderProvider }) {
  const { orderTimeSlots, orderType } = useSelector((state) => state.createNewOrder);
  const dispatch = useDispatch();
  const [selectedDateAndTime, setSelectedDateAndTime] = useState(null);
  useEffect(() => {
    if (!isEmpty(orderTimeSlots)) {
      if (provider?.id == orderProvider?.id) {
        setSelectedDateAndTime(
          {
            date: orderTimeSlots?.pickup_date || orderTimeSlots?.first_pickup_date || null,
            startTime: orderTimeSlots?.pickup_start_time || null,
            endTime: orderTimeSlots?.pickup_end_time || null,
          } || null
        );
      } else {
        setSelectedDateAndTime({
          date: provider?.schedules?.[0]?.dateUnformatted || null,
          startTime: provider?.schedules?.[0]?.times?.[0]?.default?.start_time || null,
          endTime: provider?.schedules?.[0]?.times?.[0]?.default?.end_time || null,
        });
      }
    } else {
      setSelectedDateAndTime({
        date: provider?.schedules?.[0]?.dateUnformatted || null,
        startTime: provider?.schedules?.[0]?.times?.[0]?.default?.start_time || null,
        endTime: provider?.schedules?.[0]?.times?.[0]?.default?.end_time || null,
      });
      if (provider?.id == orderProvider?.id) {
        dispatch(
          setOrderDataAction({
            orderTimeSlots: {
              ...(orderType === 'oneTime'
                ? { pickup_date: provider?.schedules?.[0]?.dateUnformatted }
                : { first_pickup_date: provider?.schedules?.[0]?.dateUnformatted }),
              pickup_start_time: provider?.schedules?.[0]?.times?.[0]?.default?.start_time,
              pickup_end_time: provider?.schedules?.[0]?.times?.[0]?.default?.end_time,
            },
          })
        );
      }
    }
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const onSelectProvider = (provider) => {
    if (provider?.id === null) {
      dispatch(
        setOrderDataAction({
          orderProvider: { id: null },
          orderTimeSlots: {
            ...(orderType === 'oneTime'
              ? { pickup_date: provider?.schedules?.[0]?.dateUnformatted }
              : { first_pickup_date: provider?.schedules?.[0]?.dateUnformatted }),
            pickup_start_time: provider?.schedules?.[0]?.times?.[0]?.default?.start_time,
            pickup_end_time: provider?.schedules?.[0]?.times?.[0]?.default?.end_time,
          },
        })
      );
    } else {
      setSelectedDateAndTime({
        date: provider?.schedules?.[0]?.dateUnformatted,
        startTime: provider?.schedules?.[0]?.times?.[0]?.default?.start_time,
        endTime: provider?.schedules?.[0]?.times?.[0]?.default?.end_time,
      });
      dispatch(
        setOrderDataAction({
          orderProvider: {
            first_name: provider?.first_name,
            last_name: provider?.last_name,
            id: provider?.id,
            profile_image: provider?.profile_image,
          },
          orderTimeSlots: {
            ...(orderType === 'oneTime'
              ? { pickup_date: provider?.schedules?.[0]?.dateUnformatted }
              : { first_pickup_date: provider?.schedules?.[0]?.dateUnformatted }),
            pickup_start_time: provider?.schedules?.[0]?.times?.[0]?.default?.start_time,
            pickup_end_time: provider?.schedules?.[0]?.times?.[0]?.default?.end_time,
          },
        })
      );
    }
  };

  const renderAvailableTimes = () => (
    <div className='mt-2 w-full'>
      <Carousel itemsToScroll={1} itemsToShow={3} pagination={false}>
        {provider?.schedules?.map((item, idx) => (
          <div
            className={
              'border-3  rounded-lg bg-[#f5f5fb] p-1 py-2 px-2 text-center text-sm font-medium' +
              ' ' +
              classNames(
                selectedDateAndTime?.date === item?.dateUnformatted && provider?.id == orderProvider?.id
                  ? ' border-client-300'
                  : ' border-[#f5f5fb]'
              )
            }
            key={idx + 'time'}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                setOrderDataAction({
                  orderProvider: {
                    first_name: provider?.first_name,
                    last_name: provider?.last_name,
                    id: provider?.id,
                    profile_image: provider?.profile_image,
                  },
                  orderTimeSlots: {
                    ...(orderType === 'oneTime'
                      ? { pickup_date: item?.dateUnformatted }
                      : { first_pickup_date: item?.dateUnformatted }),
                    pickup_start_time: item?.times?.[0]?.default?.start_time,
                    pickup_end_time: item?.times?.[0]?.default?.end_time,
                  },
                })
              );
              setSelectedDateAndTime({
                ...selectedDateAndTime,
                date: item?.dateUnformatted,
                startTime: item?.times?.[0]?.default?.start_time,
                endTime: item?.times?.[0]?.default?.end_time,
              });
            }}
          >
            <span className=' block'>{item?.dateWeekday}</span>
            <span className=''>{item?.dateMonthDate}</span>
          </div>
        ))}
      </Carousel>
      <div className='mt-2 flex'>
        <div className='flex w-full flex-col space-y-2'>
          {selectedDateAndTime?.date &&
            provider?.schedules
              ?.find((item) => item?.dateUnformatted == selectedDateAndTime?.date)
              ?.times?.map((time, i) => (
                <div
                  key={i}
                  className={
                    'border-3 w-full rounded-lg bg-[#f5f5fb] p-3 px-5 text-sm font-medium ' +
                    ' ' +
                    classNames(
                      selectedDateAndTime?.startTime === time?.default?.start_time &&
                        selectedDateAndTime?.endTime === time?.default?.end_time &&
                        provider?.id == orderProvider?.id
                        ? '  border-client-300'
                        : ' border-[#f5f5fb]'
                    )
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      setOrderDataAction({
                        orderProvider: {
                          first_name: provider?.first_name,
                          last_name: provider?.last_name,
                          id: provider?.id,
                          profile_image: provider?.profile_image,
                        },
                        orderTimeSlots: {
                          ...(orderType === 'oneTime'
                            ? { pickup_date: selectedDateAndTime?.date }
                            : { first_pickup_date: selectedDateAndTime?.date }),
                          pickup_start_time: time?.default?.start_time,
                          pickup_end_time: time?.default?.end_time,
                        },
                      })
                    );
                    setSelectedDateAndTime({
                      ...selectedDateAndTime,
                      startTime: time?.default?.start_time,
                      endTime: time?.default?.end_time,
                    });
                  }}
                >
                  {`${time?.start_time} - ${time?.end_time}`}
                </div>
              ))}
        </div>
      </div>
    </div>
  );
  const renderAvailableTimesAutoMatch = () => (
    <div className='mt-2 w-full'>
      <Carousel itemsToScroll={1} itemsToShow={3} pagination={false}>
        {provider?.schedules?.map((item, idx) => (
          <div
            className={
              'border-3  rounded-lg bg-[#f5f5fb] p-1 py-2 px-2 text-center text-sm font-medium' +
              ' ' +
              classNames(
                selectedDateAndTime?.date === item?.dateUnformatted && orderProvider?.id == null
                  ? ' border-client-300'
                  : ' border-[#f5f5fb]'
              )
            }
            key={idx + 'time'}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                setOrderDataAction({
                  orderProvider: {
                    id: null,
                  },
                  orderTimeSlots: {
                    ...(orderType === 'oneTime'
                      ? { pickup_date: item?.dateUnformatted }
                      : { first_pickup_date: item?.dateUnformatted }),
                    pickup_start_time: item?.times?.[0]?.default?.start_time,
                    pickup_end_time: item?.times?.[0]?.default?.end_time,
                  },
                })
              );
              setSelectedDateAndTime({
                ...selectedDateAndTime,
                date: item?.dateUnformatted,
                startTime: item?.times?.[0]?.default?.start_time,
                endTime: item?.times?.[0]?.default?.end_time,
              });
            }}
          >
            <span className=' block'>{item?.dateWeekday}</span>
            <span className=''>{item?.dateMonthDate}</span>
          </div>
        ))}
      </Carousel>
      <div className='mt-2 flex'>
        <div className='flex w-full flex-col space-y-2'>
          {selectedDateAndTime?.date &&
            provider?.schedules
              ?.find((item) => item?.dateUnformatted == selectedDateAndTime?.date)
              ?.times?.map((time, i) => (
                <div
                  key={i}
                  className={
                    'border-3 w-full rounded-lg bg-[#f5f5fb] p-3 px-5 text-sm font-medium ' +
                    ' ' +
                    classNames(
                      selectedDateAndTime?.startTime === time?.default?.start_time &&
                        selectedDateAndTime?.endTime === time?.default?.end_time &&
                        provider?.id == orderProvider?.id
                        ? '  border-client-300'
                        : ' border-[#f5f5fb]'
                    )
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      setOrderDataAction({
                        orderProvider: {
                          id: null,
                        },
                        orderTimeSlots: {
                          ...(orderType === 'oneTime'
                            ? { pickup_date: selectedDateAndTime?.date }
                            : { first_pickup_date: selectedDateAndTime?.date }),
                          pickup_start_time: time?.default?.start_time,
                          pickup_end_time: time?.default?.end_time,
                        },
                      })
                    );
                    setSelectedDateAndTime({
                      ...selectedDateAndTime,
                      startTime: time?.default?.start_time,
                      endTime: time?.default?.end_time,
                    });
                  }}
                >
                  {`${time?.start_time} - ${time?.end_time}`}
                </div>
              ))}
        </div>
      </div>
    </div>
  );

  const renderProvider = () => (
    <div
      className={`border-3 relative mr-1 w-72 rounded-lg bg-white pt-2 sm:mr-4 sm:w-80 ${
        provider?.id == orderProvider?.id ? 'border-client-pickupcomplete' : 'border-white'
      }`}
      onClick={() => onSelectProvider(provider)}
    >
      <div className='flex w-6 px-3'>
        <input
          type='checkbox'
          id={provider?.id}
          name={provider?.id}
          onChange={() => {}}
          checked={provider?.id == orderProvider?.id}
          className={classNames(
            provider?.id == orderProvider?.id ? 'text-client-300' : 'text-slate-200',
            ' h-5 w-5 rounded-full ring-0'
          )}
        />
      </div>
      <ProviderProfile provider={provider} type='multiple' readonly={true} />
      <hr className='go-hr-dashed my-3'></hr>
      <div className='px-2 pb-3 sm:px-4'>
        <div className='go-textblack-15 mb-1 text-center text-black'>Select Pickup Date & Time</div>
        {renderAvailableTimes()}
      </div>
    </div>
  );

  const renderAnyProvider = () => (
    <div
      className={`border-3 relative mr-1 w-72 rounded-lg bg-white pt-2 sm:mr-4 sm:w-80 ${
        orderProvider?.id == null ? 'border-client-pickupcomplete' : 'border-white'
      }`}
      onClick={() => onSelectProvider(provider)}
    >
      <div className='flex w-6 px-3'>
        <input
          type='checkbox'
          id={provider?.id}
          name={provider?.id}
          onChange={() => onSelectProvider(provider)}
          checked={orderProvider?.id == null}
          className={classNames(
            provider?.id == orderProvider?.id ? 'text-client-300' : 'text-slate-200',
            ' h-5 w-5 rounded-full ring-0'
          )}
        />
      </div>
      <div className={'mb-2 min-h-[180px] flex-row items-center'}>
        <div className={'flex w-full justify-center'}>
          <img className={' mb-4 h-[80px] w-[80px] rounded-full bg-[#f3f8ff]'} src={no_picture} alt={'Any Provider'} />
        </div>
        <div className={'w-full text-center'}>
          <div className='text-client-text font-medium'>Auto-Match Me</div>
          <div className='text-client-text my-1 text-sm' style={{ visibility: 'hidden' }}>
            1
          </div>
          <div className='text-client-text mb-3 mt-1 text-sm'>
            We'll match you with an <br />
            available Provider
          </div>
        </div>
        <hr className='go-hr-dashed mt-5 mb-3'></hr>
        <div className=' px-4 pb-3'>
          <div className='go-textblack-15 mb-1 text-center text-black'>Select Pickup Date & Time</div>
          {renderAvailableTimesAutoMatch()}
        </div>
      </div>
    </div>
  );

  return <div>{!provider?.id ? renderAnyProvider() : renderProvider()}</div>;
}
