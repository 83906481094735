//mock data for sidebar
const sidebarData = [
  {
    id: 1,
    title: 'Dashboard',
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="#FFF" fill-rule="nonzero" stroke="#FFF" stroke-width=".5" opacity=".5">
        <path d="M9.727 9H4.273C3.57 9 3 8.411 3 7.687V4.312C3 3.59 3.57 3 4.273 3h5.454C10.43 3 11 3.589 11 4.312v3.375C11 8.411 10.43 9 9.727 9zM4.273 4.125c-.1 0-.182.084-.182.187v3.375c0 .104.081.188.182.188h5.454c.1 0 .182-.084.182-.188V4.312a.185.185 0 0 0-.182-.187H4.273zM9.727 21H4.273C3.57 21 3 20.44 3 19.75v-7.5c0-.69.57-1.25 1.273-1.25h5.454c.702 0 1.273.56 1.273 1.25v7.5c0 .69-.57 1.25-1.273 1.25zm-5.454-8.929a.18.18 0 0 0-.182.18v7.499a.18.18 0 0 0 .182.179h5.454c.1 0 .182-.08.182-.179v-7.5a.18.18 0 0 0-.182-.179H4.273zM19.727 21h-5.454C13.57 21 13 20.411 13 19.688v-3.375c0-.724.57-1.313 1.273-1.313h5.454c.702 0 1.273.589 1.273 1.313v3.375C21 20.41 20.43 21 19.727 21zm-5.454-4.875c-.1 0-.182.084-.182.188v3.375c0 .103.081.187.182.187h5.454c.1 0 .182-.084.182-.187v-3.375a.185.185 0 0 0-.182-.188h-5.454zM19.727 13h-5.454C13.57 13 13 12.44 13 11.75v-7.5c0-.69.57-1.25 1.273-1.25h5.454C20.43 3 21 3.56 21 4.25v7.5c0 .69-.57 1.25-1.273 1.25zm-5.454-8.929a.18.18 0 0 0-.182.179v7.5a.18.18 0 0 0 .182.179h5.454c.1 0 .182-.08.182-.18V4.25a.18.18 0 0 0-.182-.179h-5.454z"/>
    </g>
</svg>

`,

    link: '/dashboard',
    children: null,
  },
  {
    id: 1,
    title: 'My Orders',
    icon: `
<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="#FFF" fill-rule="nonzero" opacity=".5">
        <path d="M15 16H9a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2zM9 14h3a1 1 0 0 0 0-2H9a1 1 0 0 0 0 2z"/>
        <path d="m19.74 8.33-5.44-6a1 1 0 0 0-.74-.33h-7A2.53 2.53 0 0 0 4 4.5v15A2.53 2.53 0 0 0 6.56 22h10.88A2.53 2.53 0 0 0 20 19.5V9a1 1 0 0 0-.26-.67zM14 5l2.74 3h-2a.79.79 0 0 1-.74-.85V5zm3.44 15H6.56a.53.53 0 0 1-.56-.5v-15a.53.53 0 0 1 .56-.5H12v3.15A2.79 2.79 0 0 0 14.71 10H18v9.5a.53.53 0 0 1-.56.5z"/>
    </g>
</svg>
`,
    link: '/my-orders',
    children: null,
  },
  {
    id: 1,
    title: 'My Account',
    icon: `
<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#FFF" stroke-width="1.8" fill="none" fill-rule="evenodd" opacity=".5" stroke-linecap="round" stroke-linejoin="round">
        <path d="M12.17 15.532c-3.867 0-7.17.585-7.17 2.926 0 2.342 3.282 2.948 7.17 2.948 3.868 0 7.17-.586 7.17-2.927 0-2.34-3.28-2.947-7.17-2.947zM12.17 12.191a4.596 4.596 0 1 0-4.596-4.596 4.58 4.58 0 0 0 4.564 4.596h.032z"/>
    </g>
</svg>
`,
    link: '/account',
    children: null,
  },
  //   {
  //     id: 1,
  //     title: 'Messages',
  //     icon: `
  // <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
  //   <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
  // </svg>

  // `,
  //     link: '/messages',
  //     children: null,
  //   },
  {
    id: 1,
    title: 'Services & Pricing',
    icon: `
<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#FFF" stroke-width="1.7" fill="none" fill-rule="evenodd" opacity=".5" stroke-linecap="round" stroke-linejoin="round">
        <path d="M7.545 10v6M11.995 8v8M16.445 13v3M16.217 3H7.783C4.843 3 3 5.08 3 8.027v7.946C3 18.92 4.834 21 7.783 21h8.434C19.166 21 21 18.92 21 15.973V8.027C21 5.08 19.166 3 16.217 3z"/>
    </g>
</svg>


`,
    link: '/services-and-pricing',
    children: null,
  },
  {
    id: 1,
    title: 'Promotions',
    icon: `
<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd" opacity=".5" stroke-linecap="round" stroke-linejoin="round">
        <path d="M5.045 7.306a2.262 2.262 0 0 1 2.26-2.262h1.03c.597 0 1.169-.237 1.593-.657l.719-.72a2.262 2.262 0 0 1 3.199-.009v.001l.01.008.72.72a2.26 2.26 0 0 0 1.593.657h1.028a2.262 2.262 0 0 1 2.262 2.262v1.027c0 .597.236 1.17.657 1.594l.72.72c.886.881.89 2.313.01 3.2h-.001l-.01.01-.72.72a2.256 2.256 0 0 0-.656 1.591v1.03a2.26 2.26 0 0 1-2.261 2.26h-1.031a2.26 2.26 0 0 0-1.593.658l-.72.72a2.261 2.261 0 0 1-3.197.011l-.003-.003-.01-.009-.718-.719a2.263 2.263 0 0 0-1.593-.658H7.306a2.26 2.26 0 0 1-2.261-2.26v-1.032c0-.597-.237-1.169-.658-1.592l-.72-.72a2.26 2.26 0 0 1-.01-3.197s0-.002.002-.003l.009-.009.719-.72c.42-.424.658-.996.658-1.594V7.306M9.682 14.822l5.139-5.14M14.745 14.75h.009M9.745 9.75h.009"/>
    </g>
</svg>
`,
    link: '/promotions',
    children: null,
  },
  {
    id: 1,
    title: 'Help',
    icon: `
<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd" opacity=".5" stroke-linecap="round" stroke-linejoin="round">
        <path d="M5.045 7.306a2.262 2.262 0 0 1 2.26-2.262h1.03c.597 0 1.169-.237 1.593-.657l.719-.72a2.262 2.262 0 0 1 3.199-.009v.001l.01.008.72.72a2.26 2.26 0 0 0 1.593.657h1.028a2.262 2.262 0 0 1 2.262 2.262v1.027c0 .597.236 1.17.657 1.594l.72.72c.886.881.89 2.313.01 3.2h-.001l-.01.01-.72.72a2.256 2.256 0 0 0-.656 1.591v1.03a2.26 2.26 0 0 1-2.261 2.26h-1.031a2.26 2.26 0 0 0-1.593.658l-.72.72a2.261 2.261 0 0 1-3.197.011l-.003-.003-.01-.009-.718-.719a2.263 2.263 0 0 0-1.593-.658H7.306a2.26 2.26 0 0 1-2.261-2.26v-1.032c0-.597-.237-1.169-.658-1.592l-.72-.72a2.26 2.26 0 0 1-.01-3.197s0-.002.002-.003l.009-.009.719-.72c.42-.424.658-.996.658-1.594V7.306M9.682 14.822l5.139-5.14M14.745 14.75h.009M9.745 9.75h.009"/>
    </g>
</svg>
`,
    link: '/help',
    children: null,
  },
  {
    id: 1,
    title: 'Log out',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
</svg>
`,
    link: '/signin',
    children: null,
  },
];

export default sidebarData;
