import React from 'react';
import StatusChip from '../../../components/StatusChip';
import Button from '../../../components/Button';
import ClientButton from '../../../components/ClientButton';
import RatingStars from '../../../components/RatingStars';
import no_picture from '../../../images/no-profile-picture.png';
import { useNavigate } from 'react-router-dom';
import { dateFn } from '../../../utils/dateTimeFormatter';
import { amountCheckFn, statusBgSwitch, statusTextSwitch } from '../../../utils/commonUtil';
import { generateInitials } from '../../../components/Utils';
import { titleCaseFormatter } from '../../../utils/stringFormatters';
import statusColor from '../../../utils/statusColors';
import { ArrowRightIcon, ChevronRightIcon, StarIcon } from '@heroicons/react/20/solid';
import { StarIcon as StarIcon2 } from '@heroicons/react/24/outline';
function SingleOrder({ order, tipModalOpen, ratingModalOpen }) {
  const navigate = useNavigate();
  return (
    <div className={`mb-4 rounded-lg bg-white shadow-sm sm:mb-6`}>
      <div className='items-center justify-between space-y-2 space-x-2 sm:space-y-4 md:flex md:space-y-0'>
        <div
          className='flex w-full cursor-pointer flex-col space-x-2 sm:flex-row sm:space-x-4'
          onClick={() => navigate(`/order/${order?.id}`)}
        >
          <div className=' w-full '>
            <div
              className={` hidden justify-between rounded-lg py-4 px-4 sm:flex sm:py-4 sm:px-6 ${statusBgSwitch(
                order?.status
              )} ${statusTextSwitch(order?.status)}`}
            >
              <span className='font-semibold'>{titleCaseFormatter(order?.status)} </span>
              {order?.tenant_rating && (
                <div className='flex min-w-[400px]'>
                  <div className='flex w-full items-center justify-center '>
                    <StarIcon className='mx-1 h-5 w-5 text-black' />
                    <span className='bodyText'>{order?.tenant_rating?.rating} Company Rating</span>
                  </div>
                  <span className='text-black '>/</span>
                  <div className='flex w-full items-center  justify-center'>
                    <StarIcon className='mx-1 h-5 w-5 text-black' />
                    <span className='bodyText'>{order?.worker_rating?.rating} Provider Rating</span>
                  </div>
                </div>
              )}
              {order?.status == 'DELIVERED' && !order?.tenant_rating && (
                <div className='flex min-w-[400px]'>
                  <div className='flex w-full items-center justify-center '>
                    <StarIcon2 className='mr-1 h-5 w-5 text-black' />
                    <StarIcon2 className='mr-1 h-5 w-5 text-black' />
                    <StarIcon2 className='mr-1 h-5 w-5 text-black' />
                    <StarIcon2 className='mr-1 h-5 w-5 text-black' />
                    <StarIcon2 className='mr-1 h-5 w-5 text-black' />
                    <Button
                      variant='link'
                      text='Rate Order'
                      classes={'px-0 py-0 ml-2'}
                      onClick={(e) => {
                        ratingModalOpen(e, true, order);
                      }}
                    />
                  </div>
                </div>
              )}
              <span className='bodyText'>Order #{order?.id}</span>
            </div>
            <div
              className={`flex justify-between rounded-lg py-4 px-4 sm:hidden sm:py-4 sm:px-6 ${statusBgSwitch(
                order?.status
              )} ${statusTextSwitch(order?.status)}`}
            >
              <div className='w-full flex-col'>
                <span className='font-semibold'>{titleCaseFormatter(order?.status)} </span>
                {order?.tenant_rating && (
                  <div>
                    <div className='flex w-full items-center justify-start '>
                      <StarIcon className='mr-1 h-4 w-4 text-black' />
                      <span className='bodyText mt-1'>{order?.tenant_rating?.rating} Company Rating</span>
                    </div>
                    <div className='flex w-full items-center  justify-start'>
                      <StarIcon className='mr-1 h-4 w-4 text-black' />
                      <span className='bodyText mt-1'>{order?.worker_rating?.rating} Provider Rating</span>
                    </div>
                  </div>
                )}

                {order?.status == 'DELIVERED' && !order?.tenant_rating && (
                  <div className='flex'>
                    <div className='flex w-full items-center justify-start '>
                      <StarIcon2 className='mr-1 h-5 w-5 text-black' />
                      <Button
                        variant='link'
                        text='Rate Order'
                        classes={'px-0 py-0 ml-2'}
                        onClick={(e) => {
                          ratingModalOpen(e, true, order);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className='w-full flex-row justify-end text-right'>
                <div className='bodyText text-right'>Order #{order?.id}</div>
                {/* <div className='labelDefault text-right'></div> */}
                <Button
                  variant='link'
                  text='View Details'
                  onClick={() => navigate(`/order/${order?.id}`)}
                  icon={<ChevronRightIcon className='h-4 w-4' />}
                  iconPosition='right'
                  classes={'px-0 !py-0'}
                />
              </div>
            </div>
            <div className=' flex justify-between px-4 py-3 sm:hidden sm:px-6'>
              <div className='mb-2 flex-col'>
                <div className='bodyText'>
                  {dateFn(
                    'toTZOnlyDate',
                    order?.pickup_date,
                    order?.pickup_start_time,
                    order?.pickup_end_time,
                    order?.customer_address?.time_zone
                  )}
                </div>
                <div className='bodyText'>
                  {dateFn(
                    'toTZOnlyTimes',
                    order?.pickup_date,
                    order?.pickup_start_time,
                    order?.pickup_end_time,
                    order?.customer_address?.time_zone
                  )}
                </div>
                <div className='labelDefault'>Pick Up</div>
              </div>
              <div className='flex-col text-right'>
                <div className='bodyText'>
                  {dateFn(
                    'toTZOnlyDate',
                    order?.dropoff_date,
                    order?.dropoff_start_time,
                    order?.dropoff_end_time,
                    order?.customer_address?.time_zone
                  )}
                </div>
                <div className='bodyText'>
                  {dateFn(
                    'toTZOnlyTimes',
                    order?.dropoff_date,
                    order?.dropoff_start_time,
                    order?.dropoff_end_time,
                    order?.customer_address?.time_zone
                  )}
                </div>
                <div className='labelDefault'>Delivery</div>
              </div>
            </div>
            <div className='flex justify-between rounded-lg px-4 py-3 shadow-[rgba(50,50,50,0.25)_0px_-1.5px_0px] sm:hidden sm:px-6'>
              <div className='mb-2 flex-col'>
                <div className='bodyText'>{amountCheckFn(order?.final_total)}</div>
                <div className='labelDefault'>
                  {order?.status == 'DELIVERED' ? 'Total Cost' : 'Estimated Total Cost'}
                </div>
              </div>
              <div className='flex-col text-right'>
                <div className='bodyText'>
                  {(order?.status == 'WAITING' || order?.status == 'PICKUP_COMPLETE' || order?.status == 'DELIVERED') &&
                    (order?.final_tip ? (
                      amountCheckFn(order?.final_tip)
                    ) : dateFn('daysBetween', order?.dropoff_date) > 30 ? (
                      '$0'
                    ) : (
                      <div className='flex-col'>
                        <div> {amountCheckFn(order?.final_tip)}</div>
                        <Button
                          variant='link'
                          text='Add Tip'
                          classes={'px-0 py-0 ml-2'}
                          onClick={(e) => {
                            tipModalOpen(e, true, order);
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className=' hidden justify-between px-4 py-3 sm:flex sm:px-6'>
              <div>
                <div className='mb-2 flex space-x-1'>
                  <span className='labelDefault pr-4'>Pick Up</span>
                  <span className='bodyText'>
                    {dateFn(
                      'toTZWithSeparator',
                      order?.pickup_date,
                      order?.pickup_start_time,
                      order?.pickup_end_time,
                      order?.customer_address?.time_zone
                    )}
                  </span>
                </div>
                <div className='flex space-x-1'>
                  <span className='labelDefault pr-4'>Delivery</span>
                  <span className='bodyText'>
                    {dateFn(
                      'toTZWithSeparator',
                      order?.dropoff_date,
                      order?.dropoff_start_time,
                      order?.dropoff_end_time,
                      order?.customer_address?.time_zone
                    )}
                  </span>
                </div>
              </div>
              <div className='min-w-[400px] flex-row'>
                <div className='mb-2 flex'>
                  <span className='labelDefault w-48 pr-2 text-right'>
                    {order?.status == 'DELIVERED' ? 'Total Cost' : 'Estimated Total Cost'}
                  </span>
                  <span className='bodyText w-auto'>{amountCheckFn(order?.final_total)}</span>
                </div>

                {(order?.status == 'WAITING' || order?.status == 'PICKUP_COMPLETE' || order?.status == 'DELIVERED') &&
                  (order?.final_tip ? (
                    <div className='flex'>
                      <span className='labelDefault w-48 pr-2 text-right'>Tip:</span>
                      <span className='bodyText w-auto'>{amountCheckFn(order?.final_tip)}</span>
                    </div>
                  ) : dateFn('daysBetween', order?.dropoff_date) > 30 ? (
                    <div className='flex'>
                      <span className='labelDefault w-48 pr-2 text-right'>Tip:</span>
                      <span className='bodyText'>$0</span>
                    </div>
                  ) : (
                    <div className='flex'>
                      <span className='labelDefault w-48 pr-2 text-right'>Tip:</span>
                      <span className='bodyText w-auto'>{amountCheckFn(order?.final_tip)}</span>
                      <Button
                        variant='link'
                        text='Add Tip'
                        classes={'px-0 !py-0 ml-2'}
                        onClick={(e) => {
                          tipModalOpen(e, true, order);
                        }}
                      />
                    </div>
                  ))}
              </div>
              <div className='flex'>
                <div>
                  <Button
                    variant='link'
                    text='View Details'
                    onClick={() => navigate(`/order/${order?.id}`)}
                    icon={<ChevronRightIcon className='h-6 w-6' />}
                    iconPosition='right'
                    classes={'px-0 !py-0'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleOrder;
