import React, { useEffect, useState } from 'react';
import lc_logo from '../../images/laundry-care-logo.png';
import pl_logo from '../../images/Push_Laundry_white_logo.png';
import { useDispatch } from 'react-redux';
import { resetCreateOrderFlow } from '../../redux/Reducers/CreateOrderReducer';
import SigninPage from './SigninPage';
import ForgotPasswordPage from './ForgotPasswordPage';
const appVersion = import.meta.env.VITE_APP_VERSION;
function Signin() {
  const dispatch = useDispatch();
  useEffect(() => {
    sessionStorage.removeItem('token');
    dispatch(resetCreateOrderFlow());
  }, []);
  return (
    <main className='bg-login bg-inherit'>
      <div className='relative'>
        <div className='flex flex-col after:flex-1'>
          <div className='flex-1'>
            <div className='flex flex-col after:flex-1'>
              <div className=' flex justify-center pb-10 pt-20 sm:pb-20'>
                <div className='flex justify-center'>
                  <a
                    href={
                      import.meta.env.VITE_TENANT_NAME === 'laundrycare'
                        ? 'https://www.laundrycare.biz/'
                        : 'https://www.pushlaundry.com/'
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {import.meta.env.VITE_TENANT_NAME === 'laundrycare' ? (
                      <img src={lc_logo} className='w-56' alt='Laundry Care' />
                    ) : (
                      <img src={pl_logo} className='w-56' alt='Push Laundry' />
                    )}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <SigninPage />
        </div>
        <div className='absolute bottom-0  flex w-full items-center  justify-center p-2 text-center text-xs font-semibold text-white'>
          <span className='text-[#548ff7]'></span>
        </div>
      </div>
    </main>
  );
}
export default Signin;
