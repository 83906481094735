/* eslint-disable react/prop-types */
import React from 'react';
import Loader from '../components/Loader';

export default function Button({
  variant = 'primary-dark',
  size = 'md',
  text,
  onClick,
  classes,
  disabled = false,
  type = 'button',
  loading = false,
  icon,
  iconPosition = 'left',
}) {
  const classSwitch = (key) => {
    switch (key) {
      case 'primary-dark':
        return 'rounded-full bg-client-primarydark text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:opacity-75 ';
      case 'primary-light':
        return 'rounded-full bg-client-primarylight text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:opacity-75 ';
      case 'secondary':
        return 'rounded-full bg-white text-client-text shadow-sm border-client-text border-1 ring-1 ring-inset ring-client-text hover:bg-slate-100 ';
      case 'soft':
        return 'rounded-full bg-blue-50 text-client-primary shadow-sm hover:bg-blue-100 ';
      case 'cancel':
        return 'rounded-full bg-client-cancelled text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:opacity-75 ';
      case 'link':
        return 'text-client-link ';
      case 'cancellink':
        return 'text-client-cancelled ';
      default:
        break;
    }
  };

  const sizeSwitch = (key) => {
    switch (key) {
      case 'xs':
        return 'px-1 py-0.5 bodyText ';
      case 'sm':
        return 'px-2 py-1 bodyText ';
      case 'md':
        return 'px-2.5 py-1.5 bodyText ';
      case 'base':
        return 'px-3 py-2 bodyText ';
      case 'lg':
        return 'px-3.5 py-2.5 bodyText ';
      default:
        break;
    }
  };

  const Spinner = () => {
    return (
      <svg className='h-6 w-6 animate-spin stroke-gray-500' viewBox='0 0 256 256'>
        <line x1='128' y1='32' x2='128' y2='64' strokeLinecap='round' strokeLinejoin='round' strokeWidth='24'></line>
        <line
          x1='195.9'
          y1='60.1'
          x2='173.3'
          y2='82.7'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='24'
        ></line>
        <line x1='224' y1='128' x2='192' y2='128' strokeLinecap='round' strokeLinejoin='round' strokeWidth='24'></line>
        <line
          x1='195.9'
          y1='195.9'
          x2='173.3'
          y2='173.3'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='24'
        ></line>
        <line x1='128' y1='224' x2='128' y2='192' strokeLinecap='round' strokeLinejoin='round' strokeWidth='24'></line>
        <line
          x1='60.1'
          y1='195.9'
          x2='82.7'
          y2='173.3'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='24'
        ></line>
        <line x1='32' y1='128' x2='64' y2='128' strokeLinecap='round' strokeLinejoin='round' strokeWidth='24'></line>
        <line
          x1='60.1'
          y1='60.1'
          x2='82.7'
          y2='82.7'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='24'
        ></line>
      </svg>
    );
  };
  return (
    <button
      onClick={onClick}
      disabled={disabled || loading}
      className={
        'cusor-pointer disabled:cursor-not-allowed ' +
        (disabled || loading ? 'opacity-50 ' : '') +
        classSwitch(variant) +
        sizeSwitch(size) +
        classes +
        (icon ? ' inline-flex items-center justify-center gap-x-1 ' : '')
      }
      type={type}
    >
      {icon && iconPosition === 'left' && icon}
      {loading ? Spinner() : text}
      {icon && iconPosition === 'right' && icon}
    </button>
  );
}
