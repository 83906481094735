import React, { useEffect, useState } from 'react';
import ClientButton from '../../../components/ClientButton';
import RatingStars from '../../../components/RatingStars';
import { dateFn } from '../../../utils/dateTimeFormatter';
import Loader from '../../../components/Loader';
import { titleCaseFormatter } from '../../../utils/stringFormatters';
import { getBillingDetailsApi } from '../../../redux/Reducers/commonApis';
import Stripe from '../../../components/Stripe';
import toast from 'react-hot-toast';
import { updateOrderApi } from '../../../redux/Reducers/MyAccountReducer';
import { amountCheckFn, textCheckFn } from '../../../utils/commonUtil';
import StatusChip from '../../../components/StatusChip';
export default function DeliveredStatus({ orderDetails, tipModalOpen }) {
  const [loading, setLoading] = useState(false);
  const [billingDetails, setBillingDetails] = useState({});
  const [paymentAvailable, setPaymentAvailable] = useState(false);
  const [addNewCard, setAddNewCard] = useState(false);
  const [newOrderDetails, setNewOrderDetails] = useState({
    worker_rating: orderDetails?.worker_rating?.rating || 0,
    tenant_rating: orderDetails?.tenant_rating?.rating || 0,
    feedback: orderDetails?.feedback || '',
  });
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const getBillingDetailsFn = (id) => {
    setLoading(true);
    const response = getBillingDetailsApi(id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        const details = res?.data?.addresses?.find((item) => item?.id === id);
        setBillingDetails(details || {});
        setPaymentAvailable(details?.billing_details?.default_payment_method_type || null);
        setLoading(false);
      } else {
        setPaymentAvailable('nocard');
        toast.error(res?.message || 'Something went wrong');
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (
      newOrderDetails?.worker_rating > 0 &&
      newOrderDetails?.tenant_rating > 0 &&
      newOrderDetails?.feedback?.length > 0
    ) {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }
  }, [newOrderDetails]);
  const onSaveOrderDetailsFn = async () => {
    setFeedbackLoading(true);
    const payload = {
      worker_rating: {
        rating: newOrderDetails?.worker_rating,
      },
      tenant_rating: { rating: newOrderDetails?.tenant_rating },
      feedback: newOrderDetails?.feedback,
    };
    try {
      const response = await updateOrderApi(orderDetails?.id, payload);
      if (response?.status === 200 || response?.status === 201 || response?.status === 204) {
        toast.success(response?.message || 'Rating and feedback saved successfully');
        //reload page
        window.location.reload();
      } else {
        toast.error(response?.message || 'Something went wrong');
      }
    } catch (error) {
      toast.error(error?.message || 'Something went wrong');
    } finally {
      setFeedbackLoading(false);
    }
  };
  useEffect(() => {
    getBillingDetailsFn(orderDetails?.customer_address?.id);
  }, []);
  return (
    <div className='col-span-full flex flex-col  xl:col-span-7'>
      <div className='z-0 mb-4 rounded-lg bg-white p-6 '>
        {feedbackLoading ? (
          <Loader text='saving data...' classes='h-48' />
        ) : (
          <div>
            <div className='sm:hidden'>
              <StatusChip status={orderDetails?.status} />
            </div>

            <div className='mb-5 mt-4 flex space-x-10'>
              <div className='flex-row items-center space-y-3'>
                <span className='go-label-16'>
                  <span className='font-normal'>
                    <span className='font-bold'>{orderDetails?.tenant?.name || 'Tenant'}</span>
                  </span>
                </span>
                <RatingStars
                  ratingValue={orderDetails?.tenant_rating?.rating}
                  onClick={(val) =>
                    setNewOrderDetails((prev) => {
                      return { ...prev, tenant_rating: val };
                    })
                  }
                  size={24}
                />
              </div>
              <div className='flex-row items-center space-y-3'>
                <span className='go-label-16'>
                  <span className='font-normal'>
                    <span className='font-bold'>{orderDetails?.worker?.first_name || 'Provider'}</span>
                  </span>
                </span>
                <RatingStars
                  ratingValue={orderDetails?.worker_rating?.rating}
                  onClick={(val) =>
                    setNewOrderDetails((prev) => {
                      return { ...prev, worker_rating: val };
                    })
                  }
                  size={24}
                />
              </div>
            </div>
            <hr className='go-hr-dashed '></hr>
            <div className='grid gap-0 sm:gap-4'>
              <div className='py-2 sm:py-4'>
                <label htmlFor='feedback' className='go-label-16 mb-2 block'>
                  Feedback
                </label>
                <textarea
                  id='feedback'
                  className='form-textarea go-inputtext-16'
                  rows='4'
                  required
                  onChange={(e) =>
                    setNewOrderDetails((prev) => {
                      return { ...prev, feedback: e.target.value };
                    })
                  }
                  defaultValue={orderDetails?.feedback}
                ></textarea>
              </div>
            </div>

            <div className='flex'>
              <ClientButton
                variant='primary'
                text='Save'
                size='small'
                classes=''
                disabled={!enableSave}
                onClick={() => {
                  onSaveOrderDetailsFn();
                }}
              />
            </div>
          </div>
        )}
      </div>

      <div className='z-1 rounded-lg bg-white p-6 '>
        <div className='mb-5 flex space-x-4'>
          {loading ? (
            <Loader text='loading payment details' classes='h-32 w-full' />
          ) : (
            <div className='mb-4 grid w-full gap-0 '>
              {paymentAvailable == 'STRIPE' ? (
                <div className='bg-client-inputdisabledbg rounded-lg border border-blue-100 py-2 px-4'>
                  <p className='go-label-16 py-0.5'>Payment Method</p>
                  <p className='go-textblack-16 py-0.5'>
                    {titleCaseFormatter(billingDetails?.billing_details?.default_payment_method?.card_brand)} **** ****
                    **** {billingDetails?.billing_details?.default_payment_method?.card_last4}
                  </p>
                </div>
              ) : paymentAvailable == 'ACH' || paymentAvailable == 'CHECK' ? (
                <div className='py-2 sm:py-4'>
                  <p htmlFor='paymentlabel' className='go-heading-18'>
                    {textCheckFn(paymentAvailable)}
                  </p>
                </div>
              ) : paymentAvailable == 'nocard' ? (
                <div className='py-2 sm:py-4'>
                  <p htmlFor='paymentlabel' className='go-heading-16 text-center'>
                    {`Couldn't load payment details.`}
                  </p>
                </div>
              ) : (
                <div className='py-2 sm:py-4'>
                  <p htmlFor='paymentlabel' className='go-heading-16 text-center'>
                    {`You don't have a payment method. Please add a payment method to pay for this order via My Account > Payments > Payment Info`}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        <div className='mb-5 flex  space-x-4'>
          <div className='w-1/2'>
            <div className='bg-client-inputdisabledbg rounded-lg border border-blue-100 py-2 px-4'>
              <p className='go-label-16 py-0.5'>Provider</p>
              {orderDetails?.worker ? (
                <p className='go-textblack-16 py-0.5'>
                  {orderDetails?.worker?.first_name} {orderDetails?.worker?.last_name}
                </p>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
        <hr className='go-hr-dashed '></hr>
        <div className='my-5 flex space-x-4'>
          <div className='w-1/2'>
            <div className='bg-client-inputdisabledbg rounded-lg border border-blue-100 py-2 px-4'>
              <p className='go-label-16 py-0.5'>Delivery</p>
              <p className='go-textblack-16 py-0.5'>
                {dateFn(
                  'toLocal',
                  orderDetails?.dropoff_date,
                  orderDetails?.dropoff_start_time,
                  orderDetails?.dropoff_end_time
                )}
              </p>
            </div>
          </div>
          <div className='w-1/2'>
            <div className='bg-client-inputdisabledbg rounded-lg border border-blue-100 py-2 px-4'>
              <p className='go-label-16 py-0.5'>Address</p>
              {orderDetails?.customer_address?.full_address && (
                <div>
                  <p className='go-textblack-16'>{orderDetails?.customer_address?.full_address || ''}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='grid gap-0 sm:gap-4'>
          <div className='py-2 sm:py-4'>
            <label htmlFor='feedback' className='go-label-16 mb-2 block'>
              Preferences
            </label>
            <textarea
              id='feedback'
              className='form-textarea go-inputtext-16'
              rows='4'
              disabled
              required
              defaultValue={orderDetails?.preferences}
            ></textarea>
          </div>
        </div>
      </div>
      <div className='z-0 mt-4 rounded-lg bg-white p-6'>
        {!orderDetails?.final_tip && dateFn('daysBetween', orderDetails?.dropoff_date) <= 30 && (
          <header className='mb-5 flex items-center pb-3 '>
            <h5 className='go-heading-18 flex-1'>Leave a tip for your service provider</h5>
          </header>
        )}
        <div className='flex'>
          {orderDetails?.final_tip ? (
            <div>
              <div className='go-label-16'>
                Tip for{' '}
                {orderDetails?.worker?.first_name
                  ? `${orderDetails?.worker?.first_name} ${orderDetails?.worker?.last_name}`
                  : 'Provider'}
              </div>
              <div className='go-textblack-16'>{amountCheckFn(orderDetails?.final_tip)}</div>
            </div>
          ) : dateFn('daysBetween', orderDetails?.dropoff_date) <= 30 ? (
            <ClientButton
              variant='secondary'
              className='go-default-border'
              text='Add Tip'
              onClick={(e) => {
                tipModalOpen(e, true);
              }}
            />
          ) : (
            <div>
              <div className='go-label-16'>
                Tip for{' '}
                {orderDetails?.worker?.first_name
                  ? `${orderDetails?.worker?.first_name} ${orderDetails?.worker?.last_name}`
                  : 'Provider'}
              </div>
              <div className='go-textblack-16'>$0</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
