import React, { useCallback, useEffect, useState } from 'react';
import EditIcon from '../../../components/Icons/EditIcon';
import StatusChip from '../../../components/StatusChip';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import ClientButton from '../../../components/ClientButton';
import { Link } from 'react-router-dom';
import { titleCaseFormatter } from '../../../utils/stringFormatters';
import { deactivateAcccountApi } from '../../../redux/Reducers/commonApis';
import Loader from '../../../components/Loader';
import { dateFn } from '../../../utils/dateTimeFormatter';
import { toast } from 'react-hot-toast';
import { add, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import ReactivateModal from '../../../components/ReactivateModal';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';
import { accountTypeFormatFn, textCheckFn } from '../../../utils/commonUtil';
import ServiceAddressModal from '../../CreateNewOrder/PickupAddress/ServiceAddressModal';

export default function ServiceAddressesCard({ setEdit, data, addressess, setNewAddressModalOpen }) {
  const { profileData } = useSelector((state) => state.profile);

  return (
    <div className=''>
      <div className='z-0 rounded-lg bg-white '>
        <header className='flex items-center border-b border-gray-100 p-6'>
          <h5 className='go-textnormal-18 my-1 flex-1'>Service Address</h5>
          {!isEmpty(addressess) && (
            <ClientButton variant='primary' text='Add Address' size='xs' onClick={() => setNewAddressModalOpen(true)} />
          )}
          {!isEmpty(addressess) && (
            <div className='flex'>
              {!isEmpty(addressess) && (
                <button className='go-btn-primary mr-2'>
                  <EditIcon
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </button>
              )}
            </div>
          )}
        </header>
        {!isEmpty(addressess) ? (
          addressess?.map((address, index) => (
            <div key={index} className={'p-6' + (index == addressess?.length - 1 ? '' : ' border-b border-gray-100')}>
              <div className='mb-4 flex-row space-y-1.5'>
                <div className='go-label-16'>Address Name</div>
                <div className='go-textblacknormal-16 font-medium'>{textCheckFn(address?.name)}</div>
              </div>
              <div className='mb-4 flex-row space-y-1.5'>
                <div className='go-label-16'>Address</div>
                <div className='go-textblacknormal-16 font-medium'>{textCheckFn(address?.full_address)}</div>
              </div>
              <div className='mb-4 flex-row space-y-1.5'>
                <div className='go-label-16'>Status</div>
                <div className='go-textblacknormal-16 font-medium'>
                  <StatusChip status={address?.is_address_in_active_service_area ? 'Active' : 'Inactive'} />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className={'p-6'}>
            <ClientButton variant='primary' text='Add Address' size='xs' onClick={() => setNewAddressModalOpen(true)} />
          </div>
        )}
      </div>
    </div>
  );
}
