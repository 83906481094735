import React, { useEffect, useState } from 'react';
import ClientButton from '../../../components/ClientButton';
import EditIcon from '../../../components/Icons/EditIcon';
import PickupDeliveryEdit from './PickupDeliveryEdit';
import { getBillingDetailsApi } from '../../../redux/Reducers/commonApis';
import Loader from '../../../components/Loader';
import { titleCaseFormatter } from '../../../utils/stringFormatters';
import Stripe from '../../../components/Stripe';
import { toast } from 'react-hot-toast';
import { dateFn } from '../../../utils/dateTimeFormatter';
import { amountCheckFn, textCheckFn } from '../../../utils/commonUtil';
import StatusChip from '../../../components/StatusChip';
export default function OrderInfo(props) {
  const [loading, setLoading] = useState(false);
  const [billingDetails, setBillingDetails] = useState({});
  const [paymentType, setPaymentType] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [addNewCard, setAddNewCard] = useState(false);
  const [preferences, setPreferences] = useState(props?.orderDetails?.preferences || '');
  const getBillingDetailsFn = (id) => {
    setLoading(true);
    const response = getBillingDetailsApi(id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        const details = res?.data?.addresses?.find((item) => item?.id === id);
        setBillingDetails(details || {});
        setPaymentType(details?.billing_details?.default_payment_method_type);
        setPaymentMethod(details?.billing_details?.default_payment_method);
        setLoading(false);
      } else {
        setPaymentType(false);
        toast.error(res?.message || 'Something went wrong');
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    getBillingDetailsFn(props?.orderDetails?.customer_address?.id);
  }, []);
  useEffect(() => {
    setNewOrderDetails({ ...newOrderDetails, preferences: preferences });
  }, [preferences]);

  const { orderDetails, newOrderDetails, setNewOrderDetails } = props;
  return (
    <div className='col-span-full flex flex-col  xl:col-span-7'>
      <div className='z-1 rounded-lg bg-white p-6 '>
        <header className='flex justify-between'>
          <div className=''>
            <StatusChip status={props?.status} />
          </div>
          {props.status === 'WAITING' && orderDetails?.is_editable && (
            <div className='flex'>
              <button className='go-btn-primary mr-2'>
                <EditIcon
                  onClick={() => {
                    props.setEdit({
                      ...props.edit,
                      orderPickupDeliveryEdit: !props.edit.orderPickupDeliveryEdit,
                    });
                  }}
                />
              </button>
            </div>
          )}
        </header>
        <div className='my-3 flex'>
          <div className='w-1/2'>
            {orderDetails?.customer_address?.full_address && (
              <div>
                <a href='#' className='go-textblack-16'>
                  {orderDetails?.customer_address?.name}
                </a>
                <p className='go-label-16'>{orderDetails?.customer_address?.full_address || ''}</p>
              </div>
            )}
          </div>
          <div className='w-1/2'>
            <div className='bg-client-inputdisabledbg rounded-lg border border-blue-100 py-2 px-4'>
              <p className='go-label-16 py-0.5'>Preferred Provider</p>
              {orderDetails?.worker ? (
                <p className='go-textblack-16 py-0.5'>
                  {orderDetails?.worker?.first_name} {orderDetails?.worker?.last_name}
                </p>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
        {props.edit.orderPickupDeliveryEdit && <hr className='go-hr-dashed '></hr>}
        {(props.status === 'WAITING' || props.status === 'PICKUP_COMPLETE') && <hr className='go-hr-dashed '></hr>}
        {(props.status === 'WAITING' || props.status === 'PICKUP_COMPLETE') &&
          (loading ? (
            <Loader text='loading payment details' classes='h-32' />
          ) : (
            <div className='my-4 grid gap-0 sm:gap-4 xl:grid-cols-2'>
              {paymentType == 'ACH' || paymentType == 'CHECK' ? (
                <div className='py-2 sm:py-4'>
                  <label className='go-label-16 mr-3'>Payment Method</label>
                  <div className='go-textblacknormal-16 my-2 '> {textCheckFn(paymentType)}</div>
                </div>
              ) : paymentType == 'STRIPE' && !addNewCard ? (
                <div className='bg-client-inputdisabledbg rounded-lg border border-blue-100 py-2 px-4'>
                  <p className='go-label-16 py-0.5'>Payment Method</p>
                  <p className='go-textblack-16 py-0.5'>
                    {titleCaseFormatter(billingDetails?.billing_details?.default_payment_method?.card_brand)} **** ****
                    **** {billingDetails?.billing_details?.default_payment_method?.card_last4}
                  </p>
                </div>
              ) : (
                <div className='py-2 sm:py-4'>
                  {!addNewCard && (
                    <p htmlFor='paymentlabel' className='go-heading-18'>
                      You don't have a payment method.
                    </p>
                  )}

                  {addNewCard && (
                    <div className=''>
                      <Stripe
                        addressid={props?.orderDetails?.customer_address?.id}
                        cardAdded={() => {
                          getBillingDetailsFn(props?.orderDetails?.customer_address?.id);
                          setAddNewCard(false);
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className=' flex justify-center py-2 sm:items-end sm:justify-end sm:py-4'>
                {paymentType == 'STRIPE' && !addNewCard && (
                  <ClientButton
                    variant='primary'
                    text={paymentType ? 'Update Credit Card' : 'Add a credit card'}
                    classes='mr-2'
                    size='small'
                    onClick={() => setAddNewCard(true)}
                  />
                )}
              </div>
            </div>
          ))}

        <hr className='go-hr-dashed '></hr>
        {props.edit.orderPickupDeliveryEdit === true ? (
          <div>
            <PickupDeliveryEdit
              orderDetails={orderDetails}
              newOrderDetails={newOrderDetails}
              setNewOrderDetails={setNewOrderDetails}
            />
          </div>
        ) : (
          <div>
            <div className='my-3 flex-row'>
              <div className='py-1 sm:py-2'>
                <label className='go-label-16 mr-3'>Pick-up:</label>
                <span className='go-textblacknormal-16 my-2 '>
                  {dateFn(
                    'toLocal',
                    orderDetails?.pickup_date,
                    orderDetails?.pickup_start_time,
                    orderDetails?.pickup_end_time,
                    orderDetails?.customer_address?.time_zone
                  )}
                </span>
              </div>
              <div className='py-1 sm:py-2'>
                <label className='go-label-16 mr-3'>Delivery:</label>
                <span className='go-textblacknormal-16 my-2 '>
                  {dateFn(
                    'toLocal',
                    orderDetails?.dropoff_date,
                    orderDetails?.dropoff_start_time,
                    orderDetails?.dropoff_end_time,
                    orderDetails?.customer_address?.time_zone
                  )}
                </span>
              </div>
            </div>
            <hr className='go-hr-dashed '></hr>
          </div>
        )}
        <div className='my-3 flex-row'>
          <div className='py-1 sm:py-2'>
            <label className='go-label-16 mr-3'>Preferences:</label>
            {props.edit.orderPickupDeliveryEdit ? (
              <textarea
                id='feedback'
                className='form-textarea go-inputtext-16'
                rows='4'
                required
                defaultValue={orderDetails?.preferences}
                onChange={(e) => {
                  setNewOrderDetails({ ...newOrderDetails, preferences: e.target.value });
                }}
              ></textarea>
            ) : (
              <div className='go-textblacknormal-16 my-2 '> {orderDetails?.preferences}</div>
            )}
          </div>
        </div>
      </div>
      {(props.status === 'WAITING' || props.status === 'PICKUP_COMPLETE') && (
        <div className='z-0 mt-4 rounded-lg bg-white p-6'>
          {!orderDetails?.final_tip && (
            <header className='flex items-center pb-3'>
              <h5 className='go-heading-18 flex-1'>Add a tip for your service provider</h5>
            </header>
          )}
          <div className=' flex'>
            {orderDetails?.final_tip ? (
              <div>
                <div className='go-label-16'>
                  Tip for {orderDetails?.worker?.first_name} {orderDetails?.worker?.last_name}
                </div>
                <div className='go-textblack-16'>{amountCheckFn(orderDetails?.final_tip)}</div>
              </div>
            ) : (
              <ClientButton
                variant='secondary'
                className='go-default-border'
                text='Add Tip'
                onClick={(e) => {
                  props.tipModalOpen(e, true);
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
