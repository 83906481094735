import React, { useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import lc_logo from '../../src/images/laundry-care-logo.png';
import lc_icon from '../../src/images/laundry_care_icon.png';
import pl_logo from '../../src/images/Push_Laundry_white_logo.png';
import pl_icon from '../../src/images/push_laundry_icon.png';
import { useSelector } from 'react-redux';
function Header({ sidebarOpen, setSidebarOpen }) {
  const { profileData } = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const checkTitleName = (path) => {
    switch (path) {
      case '/':
        return 'Dashboard';
      case '/promotions':
        return 'Promotions';
      case '/dashboard':
        return 'Dashboard';
      case '/my-orders':
        return 'My Orders';
      case '/account':
        return 'My Account';
      case '/services-and-pricing':
        return 'Services and Pricing';
      case '/help':
        return 'Help';
      case '/privacy':
        return 'Privacy';
      case '/tos':
        return 'Terms and Conditions';
      case '/create-new-order':
        return '';
      case '/order':
        return '';
      default:
        return '';
    }
  };
  return (
    <header className={' top-0 z-30 bg-white' + (pathname == '/create-new-order' ? '  hidden' : ' ')}>
      <div className='bg-white px-4 sm:px-6'>
        <div className='flex h-14 items-center justify-between'>
          <div className='hidden sm:flex-row-reverse'>
            <button
              className='text-slate-500 hover:text-slate-600 lg:hidden'
              aria-controls='sidebar'
              aria-expanded={sidebarOpen}
              onClick={(e) => {
                e.stopPropagation();
                setSidebarOpen(!sidebarOpen);
              }}
            >
              <span className='sr-only'>Open sidebar</span>
              <svg className='h-6 w-6 fill-current' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                <rect x='4' y='5' width='16' height='2' />
                <rect x='4' y='11' width='16' height='2' />
                <rect x='4' y='17' width='16' height='2' />
              </svg>
            </button>
          </div>
          <div className='flex'>
            {pathname == '/' || pathname == '/dashboard' || pathname.includes('/order/') ? (
              <NavLink end to='/' className='rounded-lg sm:hidden 2xl:mb-2'>
                {import.meta.env.VITE_TENANT_NAME === 'laundrycare' ? (
                  <img src={lc_logo} alt='laundry care icon' className='  w-40' />
                ) : (
                  <img src={pl_logo} alt='push laundry icon' className='  w-40' />
                )}
              </NavLink>
            ) : (
              <h3 className='go-textblackbold-24 sm:hidden sm:pb-0'>{checkTitleName(pathname)}</h3>
            )}

            <h3 className='go-textblackbold-24 hidden sm:block sm:pb-0'>{checkTitleName(pathname)}</h3>
          </div>
          {/*  <div className='flex items-center space-x-3'>
            <DropdownNotifications align='right' /> 
           <div className='relative inline-flex'>
              <button
                className='group inline-flex items-center justify-center'
                aria-haspopup='true'
                onClick={() => navigate('/account')}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='currentColor'
                  className='h-7 w-7  rounded-full bg-white p-1.5'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z'
                  />
                </svg>
                <div className='flex items-center truncate'>
                  <span className='ml-2 truncate text-sm font-medium text-black'>{profileData?.full_name}</span>
                </div>
              </button>
            </div> 
          </div>*/}
        </div>
      </div>
    </header>
  );
}

export default Header;
