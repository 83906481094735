import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { dateFn } from '../utils/dateTimeFormatter';
import default_promo from '../images/default-promo.jpg';
import Loader from './Loader';
function PromotionCard({ promo, loading = false }) {
  const [promoApplyBtnShow, setPromoApplyBtnShow] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      className={` w-full cursor-pointer rounded-xl bg-white ${
        promo ? ' transition-[50] md:transition-[72]' : 'transition-[50] md:transition-[72]'
      } `}
    >
      {loading ? (
        <Loader classes='h-40 w-full rounded-xl' text='loading promo...' bg='true' />
      ) : (
        <>
          <div
            className={`relative h-44 flex-row items-center overflow-hidden rounded-xl !bg-contain !bg-no-repeat p-5 md:h-56`}
            style={{
              background: `linear-gradient(250deg, #52c3fb 62%, transparent 20%),url(${
                promo?.image?.absolute_url || default_promo
              })`,
            }}
            onClick={() => {
              setPromoApplyBtnShow(!promoApplyBtnShow);
            }}
          >
            <div className='flex items-center justify-end'>
              <div className='w-3/5 flex-row items-center text-center text-white'>
                {promo?.type == 'PERCENTAGE_OFF' ? (
                  <p className='text-xl font-extrabold tracking-wider sm:text-4xl '>Save {promo?.type_amount}%</p>
                ) : (
                  <p className='text-xl font-extrabold tracking-wider sm:text-4xl '>Save ${promo?.type_amount}</p>
                )}

                {promo?.code && <p className='text-base font-bold md:my-2 md:text-xl'>use code : {promo?.code}</p>}
                <p className='my-2 text-xs leading-4 md:my-2 md:text-sm'>{promo?.message}</p>
                <p className='text-xs md:my-2 md:text-sm'>
                  Offer expires {promo?.end_date ? dateFn('type2', promo?.end_date) : 'N/A'}
                </p>
              </div>
            </div>
          </div>
          {/* {promoApplyBtnShow && (
            <div className=' bottom-0 right-0 p-4 transition-[50]'>
              <ClientButton
                variant='secondary'
                className='go-default-border'
                text='Apply to a new order'
                onClick={() => navigate('/create-new-order')}
              />
            </div>
          )} */}
        </>
      )}
    </div>
  );
}

export default PromotionCard;
