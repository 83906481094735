import React, { useCallback, useState } from 'react';
import EditIcon from '../../../components/Icons/EditIcon';
import { Link } from 'react-router-dom';
import PhoneNumbersList from '../../../components/PhoneNumbersList';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import ClientButton from '../../../components/ClientButton';
import { isEmpty } from 'lodash';
import Loader from '../../../components/Loader';
import toast from 'react-hot-toast';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import ModalBasic from '../../../components/Modal';
import Button from '../../../components/Button';

export default function MyUser({ edit, setEdit, data }) {
  const password1Ref = React.useRef();
  const password2Ref = React.useRef();
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [passwordError, setPasswordError] = useState([]);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [isPassword1Valid, setIsPassword1Valid] = useState(false);
  const [isPassword2Valid, setIsPassword2Valid] = useState(false);

  const onPassword1ChangeFn = (e) => {
    const regex = /^(?=.*[A-Z].*[A-Z])(?=.*[a-z].*[a-z])(?=.*[\W_].*[\W_])(?=.*\d.*\d).{14,}$/;
    if (e.target.value?.length >= 8) {
      setIsPassword1Valid(true);
    } else {
      setIsPassword1Valid(false);
    }
  };
  const onPassword2ChangeFn = (e) => {
    if (e.target.value === password1Ref?.current?.value) {
      setIsPassword2Valid(true);
    } else {
      setIsPassword2Valid(false);
    }
  };
  const onChangePasswordFn = () => {
    setPasswordLoading(true);
    const response = resetPasswordApi({
      new_password: password1Ref?.current?.value,
      confirm_new_password: password2Ref?.current?.value,
    });
    response?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setPasswordLoading(false);
        toast.success('Reset password successfully');
        setChangePasswordModal(false);
      } else {
        setPasswordLoading(false);
        setPasswordError(res?.messages || 'Something went wrong');
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  const changePasswordFn = useCallback((e, val) => {
    e.stopPropagation();
    setChangePasswordModal(val);
  }, []);

  return (
    <div className=''>
      <div className='z-0 rounded-lg bg-white '>
        <header className='flex items-center border-b border-gray-100 p-6'>
          <h5 className='go-textnormal-18 my-1 flex-1'>My User</h5>
          <div className='flex'>
            <button className='go-btn-primary mr-2'>
              <EditIcon
                onClick={() => {
                  setEdit(!edit);
                }}
              />
            </button>
          </div>
        </header>
        <div className='p-6'>
          <div className='mb-4 flex-row space-y-1.5'>
            <div className='go-label-16'>Name</div>
            {data?.account_type === 'ORGANIZATION' ? (
              <div className='go-textblacknormal-16 font-medium'>
                {data?.org_admin_first_name + ' ' + data?.org_admin_last_name}
              </div>
            ) : (
              <div className='go-textblacknormal-16 font-medium'>{data?.first_name + ' ' + data?.last_name}</div>
            )}
          </div>
          <div className='mb-4 flex-row space-y-1.5'>
            <div className='go-label-16'>Email</div>
            <div className='go-textblacknormal-16 font-medium'>{data?.email}</div>
          </div>
          <div className='mb-4 flex-row space-y-1.5'>
            {data?.account_type === 'ORGANIZATION' ? (
              <PhoneNumbersList phoneNumbers={data?.org_admin_phone_number && data?.org_admin_phone_number} />
            ) : (
              <PhoneNumbersList phoneNumbers={data?.phone_numbers && data?.phone_numbers} />
            )}
          </div>
          <div className='flex'>
            <LockClosedIcon className='go-link-16 mr-1 h-5 w-5 font-semibold' />
            <Link
              to='#'
              className='go-link-16'
              onClick={(e) => {
                changePasswordFn(e, true);
              }}
            >
              Reset Password
            </Link>
          </div>
        </div>
      </div>

      <ModalBasic
        id='reset-password-modal'
        modalOpen={changePasswordModal}
        setModalOpen={setChangePasswordModal}
        title={'Reset Password'}
        width='md'
      >
        <div>
          {passwordLoading ? (
            <Loader text-='processing...' classes='h-40' />
          ) : (
            <div className='p-5'>
              <div className='relative mb-4 flex-row space-y-1.5'>
                <label className='go-label-16 mb-1 block' htmlFor='label'>
                  New password
                </label>
                <input
                  ref={password1Ref}
                  name='password1'
                  className={`go-inputtext-16 ` + (isPassword1Valid && isPassword2Valid ? 'border-green-500' : '')}
                  type='password'
                  onChange={(e) => onPassword1ChangeFn(e)}
                  defaultValue=''
                />
                {!isPassword1Valid && (
                  <p className='go-textnormal-14 text-red-500'>
                    * Passwords must be at least 14 characters, include 2 upper case and 2 lower case letters, and
                    include two special characters.
                  </p>
                )}
              </div>
              <div className='relative mb-4 flex-row space-y-1.5'>
                <label className='go-label-16 mb-1 block' htmlFor='label'>
                  Repeat new password
                </label>
                <input
                  ref={password2Ref}
                  name='password2'
                  className={`go-inputtext-16 ` + (isPassword1Valid && isPassword2Valid ? 'border-green-500' : '')}
                  type='password'
                  onChange={(e) => onPassword2ChangeFn(e)}
                  defaultValue=''
                />
                {isPassword1Valid && !isPassword2Valid && (
                  <p className='go-textnormal-14 text-red-500'>Password does not match.</p>
                )}
              </div>
              {!isEmpty(passwordError) &&
                passwordError?.map((error, idx) => (
                  <p key={idx} className='go-textnormal-14 text-red-500'>
                    {error}
                  </p>
                ))}
            </div>
          )}
        </div>
        <div className='flex space-x-4 p-5'>
          <Button variant='secondary' text='Cancel' classes='w-full' onClick={() => setChangePasswordModal(false)} />
          <Button
            variant='primary-dark'
            text='Save'
            classes='w-full'
            onClick={() => {
              onChangePasswordFn();
            }}
            disabled={!isPassword2Valid || passwordLoading}
          />
        </div>
      </ModalBasic>
    </div>
  );
}
