import React from 'react';
import PropTypes from 'prop-types';
import lcorderbg from '../../../images/lc_backend_image.png';
import ploderbg from '../../../images/pl_backend_image.png';
import Loader from '../../../components/Loader';
import { Link, useNavigate } from 'react-router-dom';
import ClientButton from '../../../components/ClientButton';
import { titleCaseFormatter } from '../../../utils/stringFormatters';
import CheckmarkIcon from '../../../components/Icons/CheckmarkIcon';
import calendar_icon from '../../../icons/icons-checkmark-round-green.svg';
import { dateFn } from '../../../utils/dateTimeFormatter';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
function Order({ loading, data }) {
  const navigate = useNavigate();
  const { profileData } = useSelector((state) => state.profile);
  const textFormatterFn = (order) => {
    return `${dateFn('MM/DD/YYYY', order?.status_modified_date)} at 
                            ${dateFn('timeLocal', order?.status_modified_date)}`;
  };

  const renderOrderStatus = () => {
    if (loading) {
      return <Loader classes='h-96 w-full rounded-xl' text='loading recent order...' bg='true' />;
    }

    if (!data || isEmpty(data)) {
      return (
        <div className='w-full rounded-xl bg-white'>
          <div className='justify-center p-12 xl:flex'>
            {import.meta.env.VITE_TENANT_NAME === 'laundrycare' ? (
              <img src={lcorderbg} alt='Laundry Care' />
            ) : (
              <img src={ploderbg} alt='Push Laundry' />
            )}
          </div>
          <div className='go-text-semibold-18 p-8 text-center'>You have no upcoming orders</div>
          <div
            className={
              profileData?.status !== 'ACTIVE' || !profileData?.is_allowed_to_create_order
                ? 'pointer-events-none opacity-50'
                : ''
            }
          >
            <div className='flex px-12 pb-10 text-center sm:pb-40'>
              <Link
                className='go-textwhite-15 w-full rounded-full bg-[#548ff7] py-3 text-center'
                to='/create-new-order'
              >
                Create Order
              </Link>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='w-full rounded-xl bg-white'>
        <header className='p-5'>
          <div className='go-textblack-18 text-center font-normal'>
            Your order status is <span className='font-semibold'>{titleCaseFormatter(data?.status)}</span>
          </div>
          <Link to={`/order/${data?.id}`} className='go-link-18 my-1 block text-center font-normal'>
            Order #{data?.id}
          </Link>
        </header>
        <div className='px-5 py-5 md:px-10'>
          <div className='w-full'>
            <div className='container mx-auto flex flex-wrap sm:px-5'>
              <div className='flex w-full flex-wrap'>
                <div className='w-full text-xs'>
                  {data?.status !== 'DELIVERED' && (
                    <div>
                      <div className='relative flex pb-6'>
                        <div className='absolute inset-0 top-[40%] flex h-full w-5 items-center justify-center'>
                          <div className='pointer-events-none h-full w-5 bg-gray-100'></div>
                        </div>
                        <div className='relative z-10 m-auto inline-flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full bg-[#bfa5ff] text-white'></div>

                        <div className='ml-5 flex-grow rounded-lg border border-[#bfa5ff] p-5'>
                          <h2 className='go-text-semibold-14 text-center text-[#bfa5ff]'>Waiting</h2>
                        </div>
                      </div>

                      <div className='relative flex pb-6'>
                        <div className='absolute inset-0 top-[40%]  flex  h-full w-5 items-center justify-center'>
                          <div className='pointer-events-none h-full w-5 bg-gray-100'></div>
                        </div>
                        <div
                          className={`relative z-10 m-auto inline-flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full  text-white ${
                            data?.status === 'PICKUP_COMPLETE' ? 'bg-client-pickupcomplete' : 'bg-gray-300'
                          }`}
                        ></div>
                        <div
                          className={`ml-5 flex-grow rounded-lg border p-5 ${
                            data?.status === 'PICKUP_COMPLETE' ? 'border-client-pickupcomplete' : 'border-gray-200'
                          }`}
                        >
                          <h2
                            className={`go-text-semibold-14 text-center  ${
                              data?.status === 'PICKUP_COMPLETE' ? 'text-client-pickupcomplete' : 'text-gray-300'
                            }`}
                          >
                            Pickup Complete{' '}
                            {data?.status != 'WAITING' &&
                              `${dateFn('MM/DD/YYYY', data?.status_modified_date)} at 
                            ${dateFn('timeLocal', data?.status_modified_date)}`}
                          </h2>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='relative flex'>
                    {data?.status !== 'DELIVERED' && (
                      <div className='relative z-10 m-auto inline-flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full bg-gray-300 text-white'></div>
                    )}
                    <div
                      className={`flex-grow rounded-lg border border-gray-200 p-5 ${
                        data?.status === 'DELIVERED' ? '' : 'ml-5'
                      }`}
                    >
                      <h2
                        className={`go-text-semibold-14 flex items-center justify-center text-center align-middle  ${
                          data?.status === 'DELIVERED' ? 'text-client-completedstep' : 'text-gray-300'
                        }`}
                      >
                        {data?.status === 'DELIVERED' && (
                          <img src={calendar_icon} alt='tick' className='mr-2 h-5 w-5' />
                        )}

                        {`Delivered ${data?.status === 'DELIVERED' ? textFormatterFn(data) : ''}`}
                      </h2>
                    </div>
                  </div>
                  {data?.status === 'DELIVERED' && (
                    // <Link
                    //   className='go-textwhite-15 my-8 w-full rounded-full bg-[#548ff7] py-3 text-center lg:hidden xl:block'
                    //   to='/create-new-order'
                    // >
                    //   Create Order
                    // </Link>
                    <div className='flex justify-center'>
                      {/* <ClientButton
                        className='go-textwhite-15  rounded-full bg-[#548ff7] px-4 py-2 text-center 2xl:hidden '
                        variant='primary'
                        text='Create Order'
                        type='button'
                        onClick={() => {
                          navigate('/create-new-order');
                        }}
                      /> */}
                      <Link
                        className='go-textwhite-15 my-8 w-full rounded-full bg-[#548ff7] py-3 text-center lg:hidden xl:block'
                        to='/create-new-order'
                      >
                        Create Order
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='hidden justify-center p-12 xl:flex'>
              {import.meta.env.VITE_TENANT_NAME === 'laundrycare' ? (
                <img src={lcorderbg} alt='Laundry Care' />
              ) : (
                <img src={ploderbg} alt='Push Laundry' />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div className='grow'>{renderOrderStatus()}</div>;
}

Order.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

export default Order;
