import React, { useState } from 'react';
import TableFilter from '../../../components/TableFilter';
import Loader from '../../../components/Loader';
import NoData from '../../../components/NoData';
import StatusChip from '../../../components/StatusChip';
import EditIcon from '../../../components/Icons/EditIcon';
import ClientButton from '../../../components/ClientButton';
import { phone_mask_US_format, textCheckFn } from '../../../utils/commonUtil';
import DeleteIcon from '../../../components/Icons/DeleteIcon';
import { TrashIcon } from '@heroicons/react/24/outline';
export default function MembersTable({
  members,
  setNewAddressModalOpen,
  setActiveTab,
  setEdit,
  setEditMemberModalOpen,
  setSelectedMemberId,
}) {
  const [loading, setLoading] = useState(false);
  return (
    <div className='mt-6'>
      <div className=' rounded-lg bg-white'>
        <header className='flex items-center justify-between p-5 pb-2'>
          <div>
            <h5 className='go-textblackmedium-18 my-1 flex-1 font-semibold'>Members</h5>
            <div className='flex items-center'>
              <span className='mr-2'>Edit All</span>
              <EditIcon
                onClick={() => {
                  setEdit(true);
                }}
              />
            </div>
          </div>
          <ClientButton variant='primary' text='Add Member' size='xs' onClick={() => setNewAddressModalOpen(true)} />
        </header>
        <div className=''>
          <div className=' min-h-auto overflow-x-auto shadow-sm'>
            <table className='w-full table-auto divide-y divide-slate-200 '>
              <thead className='border-b border-slate-200'>
                <tr>
                  <th className='go-textblacksemibold-12 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5'>
                    <TableFilter text='Name' align='text-left' sort={false} />
                  </th>
                  <th className='go-textblacksemibold-12 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5'>
                    <TableFilter text='Phone' align='text-left' sort={false} />
                  </th>
                  <th className='go-textblacksemibold-12 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5'>
                    <TableFilter text='Email' align='text-center' sort={false} />
                  </th>
                  <th className='go-textblacksemibold-12 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5'>
                    <TableFilter text='Assigned Addresses' align='text-center' sort={false} />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={5} className='py-10 text-center'>
                      <Loader text='loading members...' classes='h-96' />
                    </td>
                  </tr>
                ) : members?.length === 0 ? (
                  <tr>
                    <td colSpan={5} className='py-10 text-center'>
                      <NoData text='No members found' />
                    </td>
                  </tr>
                ) : (
                  members?.map((member, idx) => {
                    return (
                      <tr className={`cursor-pointer ${idx % 2 ? '' : 'bg-slate-50'}`} key={idx}>
                        <td
                          className='go-textblack-15 whitespace-nowrap px-2 py-2 align-top first:pl-5 last:pr-5'
                          style={{ verticalAlign: 'top' }}
                        >
                          {textCheckFn(member?.first_name)} {textCheckFn(member?.last_name)}
                        </td>
                        <td className='go-textblack-15 whitespace-nowrap px-2 py-2 align-top first:pl-5 last:pr-5'>
                          {phone_mask_US_format(member?.phone_numbers[0]?.phone_number)?.formatted}
                        </td>
                        <td className='go-textblack-15 whitespace-nowrap px-2 py-2 align-top first:pl-5 last:pr-5'>
                          {member?.email}
                        </td>
                        <td className='whitespace-nowrap px-2 py-2 align-top first:pl-5 last:pr-5'>
                          {member?.addresses?.map((address, index1) => (
                            <div className='go-textblack-15' key={index1}>
                              {address?.name} at {address?.full_address}
                            </div>
                          ))}
                        </td>
                        <td className='go-textblack-15 w-1/5 justify-items-end whitespace-nowrap px-2 py-2 align-top first:pl-5  last:pr-5'>
                          <div className='flex space-x-2'>
                            <div className='bottom-1 rounded-md border border-red-500 p-1.5 opacity-50'>
                              <TrashIcon className='h-5 w-5 text-red-500 ' />
                            </div>
                            <EditIcon
                              onClick={() => {
                                setEditMemberModalOpen(true);
                                setSelectedMemberId(member?.id);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
