import React, { useCallback, useEffect, useState } from 'react';
import DropdownSingle from '../../../components/DropdownSingle';
import ClientButton from '../../../components/ClientButton';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import { slugFormatter, titleCaseFormatter } from '../../../utils/stringFormatters';
import { getOrderPreferencesApi, updateOrderPreferencesApi } from '../../../redux/Reducers/MyAccountReducer';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { preferencesData } from '../../../mockdata/common';
import { isEmpty, set } from 'lodash';
import { setGetOrderPreferencesAction, setPreferencesLoadingAction } from '../../../redux/Actions/MyOrdersActions';
import Loader from '../../../components/Loader';
import ModalBasic from '../../../components/Modal';
import Button from '../../../components/Button';
export default function OrderPreferencesModal({ modalOpen, setModalOpen }) {
  const dispatch = useDispatch();
  const { selectedAddress } = useSelector((state) => state.serviceAddresses);
  const { orderPreferences, preferencesLoading } = useSelector((state) => state.myaccount);
  const [notifyModal, setNotifyModal] = useState(false);
  const [selectedPreferences, setSelectedPreferences] = useState({
    detergent: preferencesData.detergent[0],
    water_temperature: preferencesData.water_temperature[0],
    pickup_and_delivery: preferencesData.pickup_and_delivery[0],
    softener: preferencesData.softener[0],
    dry_temperature: preferencesData.dry_temperature[0],
    additional_preferences: { value: '' },
  });
  useEffect(() => {
    setNotifyModal(true);
  }, []);

  const onPreferenceChangeFn = (type, val) => {
    setSelectedPreferences((prevState) => ({
      ...prevState,
      [type]: val,
    }));
  };

  const buildStringFn = () => {
    let str = '';
    Object.keys(selectedPreferences).forEach((key) => {
      if (key === 'additional_preferences') {
        str += `${titleCaseFormatter('additional_preferences')} : ${selectedPreferences[key].value} `;
      } else {
        str += `${titleCaseFormatter(key)} : ${selectedPreferences[key].value} , `;
      }
    });
    return str;
  };

  const updatePreferencesFn = (payload) => {
    dispatch(setPreferencesLoadingAction(true));
    const response = updateOrderPreferencesApi(selectedAddress?.id, payload);
    response?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        toast.success(res?.message || 'Order preferences updated successfully');
        selectedAddress && dispatch(getOrderPreferencesApi(selectedAddress?.id));
        setOrderPreference(false);
        dispatch(setPreferencesLoadingAction(false));
      } else {
        dispatch(setPreferencesLoadingAction(false));
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  const onOrderPreferencesSaveFn = () => {
    const payload = {
      preferences: buildStringFn(),
    };
    updatePreferencesFn(payload);
  };
  useEffect(() => {
    selectedAddress && dispatch(getOrderPreferencesApi(selectedAddress?.id));
  }, [selectedAddress]);
  useEffect(() => {
    const preferences = orderPreferences?.preferences?.split(',');
    const preferencesObj = {};
    preferences?.forEach((preference) => {
      const [key, value] = preference.split(':');
      preferencesObj[slugFormatter(key?.trim())] = { value: value?.trim() };
    });

    if (orderPreferences && !isEmpty(preferencesObj)) {
      setSelectedPreferences(preferencesObj);
    } else {
      setSelectedPreferences({
        detergent: preferencesData.detergent[0],
        water_temperature: preferencesData.water_temperature[0],
        pickup_and_delivery: preferencesData.pickup_and_delivery[0],
        softener: preferencesData.softener[0],
        dry_temperature: preferencesData.dry_temperature[0],
        additional_preferences: { value: '' },
      });
    }
  }, [orderPreferences]);

  return (
    <ModalBasic
      id='order-preferences-modal'
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={'Order Preferences'}
      width='4xl'
    >
      <div className=''>
        <div className='z-0 rounded-lg bg-white '>
          <p className='text-client-cancelled go-text-16 px-5'>
            Please note: This change will not update the preferences of your current orders.
          </p>
          {/* <header className='flex-row items-center border-b border-gray-100 p-6'>
            <h5 className='go-textnormal-18 my-1 flex-1'>Order Preferences</h5>
          </header> */}
          {preferencesLoading ? (
            <Loader text='loading preferences...' classes='h-60' />
          ) : (
            <div className='grid gap-5 p-6 md:grid-cols-3'>
              <div>
                <div>
                  <label className='go-label-16' htmlFor='default'>
                    Detergent
                  </label>
                  <DropdownSingle
                    dropdownValues={preferencesData?.detergent}
                    defaultValue={selectedPreferences?.detergent}
                    onchange={(val) => onPreferenceChangeFn('detergent', { value: val })}
                  />
                </div>
              </div>
              <div>
                <div>
                  <label className='go-label-16' htmlFor='default'>
                    Water Temperature
                  </label>
                  <DropdownSingle
                    dropdownValues={preferencesData?.water_temperature}
                    defaultValue={selectedPreferences?.water_temperature}
                    onchange={(val) => onPreferenceChangeFn('water_temperature', { value: val })}
                  />
                </div>
              </div>
              <div>
                <div>
                  <label className='go-label-16' htmlFor='default'>
                    Pickup and Delivery
                  </label>
                  <DropdownSingle
                    dropdownValues={preferencesData?.pickup_and_delivery}
                    defaultValue={selectedPreferences?.pickup_and_delivery}
                    onchange={(val) => onPreferenceChangeFn('pickup_and_delivery', { value: val })}
                  />
                </div>
              </div>
              <div>
                <div>
                  <label className='go-label-16' htmlFor='default'>
                    Softener
                  </label>
                  <DropdownSingle
                    dropdownValues={preferencesData?.softener}
                    defaultValue={selectedPreferences?.softener}
                    onchange={(val) => onPreferenceChangeFn('softener', { value: val })}
                  />
                </div>
              </div>
              <div>
                <div>
                  <label className='go-label-16' htmlFor='default'>
                    Dry Temperature
                  </label>
                  <DropdownSingle
                    dropdownValues={preferencesData?.dry_temperature}
                    defaultValue={selectedPreferences?.dry_temperature}
                    onchange={(val) => onPreferenceChangeFn('dry_temperature', { value: val })}
                  />
                </div>
              </div>
              <div>
                <div>
                  <label className='go-label-16' htmlFor='default'>
                    Additional Preferences
                  </label>
                  <textarea
                    id='feedback'
                    className='form-textarea go-inputtext-16'
                    rows='4'
                    required
                    defaultValue={selectedPreferences?.additional_preferences?.value}
                    onChange={(e) => onPreferenceChangeFn('additional_preferences', { value: e.target.value })}
                  ></textarea>
                </div>
              </div>
            </div>
          )}

          <div className=' flex space-x-4 p-5 sm:px-56'>
            <Button variant='primary-dark' text='Save' classes='w-full' onClick={() => onOrderPreferencesSaveFn()} />
            <Button variant='secondary' text='Cancel' classes='w-full' onClick={() => setModalOpen(false)} />
          </div>
        </div>
        <div className='py-4'>
          <ModalWithHeadingSmall
            modalOpen={notifyModal}
            setModalOpen={setNotifyModal}
            title='Please note: This change will not update the preferences of your current orders.'
            classes='max-w-2xl py-10'
          >
            <div className='mx-8'>
              <div className='flex-col justify-center py-5 text-center '>
                <ClientButton
                  variant='primary'
                  text='Close'
                  classes=''
                  onClick={() => {
                    setNotifyModal(false);
                  }}
                />
              </div>
            </div>
          </ModalWithHeadingSmall>
        </div>
      </div>
    </ModalBasic>
  );
}
