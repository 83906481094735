import React, { useCallback, useState, Suspense, useEffect } from 'react';
import SingleOrder from './SingleOrder';
import DropdownSingle from '../../../components/DropdownSingle';
import { currentOrders, rangeDropdown, statusDropdown, tipAmount, ratingData } from '../../../mockdata/myOrder';
import SearchInput from '../../../components/SearchInput';
import TipModal from '../../../components/TipModal';
import RatingModal from './RatingModal';
import Loader from '../../../components/Loader';
import { fetchAllOrders } from '../../../redux/Reducers/MyOrdersReducer';
import { isEmpty } from 'lodash';
import PaginationNumeric from '../../../components/PaginationNumeric';
import NoOrders from './NoOrders';
import OrderFiltersPopover from './OrderFiltersPopover';
import moment from 'moment';
export default function MyOrders() {
  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
    filter: [],
    sort: {
      column: 'pickup_date',
      order: 'desc',
    },
  });
  const [orderSelected, setOrderSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [tipModal, setTipModal] = useState(false);
  const [ratingModal, setRatingModal] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [searchFilter, setSearchFilter] = useState(null);
  const [rangeStartFilter, setRangeStartFilter] = useState(null);
  const [rangeEndFilter, setRangeEndFilter] = useState(null);
  const [paginationData, setPaginationData] = useState(null);

  const tipModalFn = useCallback((e, val, order) => {
    e.stopPropagation();
    setTipModal(val);
    setOrderSelected(order);
  }, []);
  const ratingModalFn = useCallback((e, val, order) => {
    e.stopPropagation();
    setRatingModal(val);
    setOrderSelected(order);
  }, []);

  const onFiltersChange = useCallback(
    (type, val) => {
      if (type === 'status') {
        if (val === 'ALL') {
          setStatusFilter({ val: 'empty' });
        } else {
          setStatusFilter({
            columns: ['status'],
            operation: '=',
            value: val,
          });
        }
      }
      if (type === 'search') {
        if (isEmpty(val)) {
          setSearchFilter({ val: 'empty' });
        } else {
          setSearchFilter({
            columns: ['id'],
            operation: 'like',
            value: val,
          });
        }
      }
      if (type === 'range') {
        if (val?.type === 'All') {
          setRangeStartFilter({ val: 'empty' });
          setRangeEndFilter({ val: 'empty' });
        } else {
          setRangeStartFilter({
            columns: [val?.type === 'pickup' ? 'pickup_date' : 'dropoff_date'],
            operation: '>=',
            value: `${moment(val?.date).format('YYYY-MM-DD')}T00:00:00.000Z`,
          });
          setRangeEndFilter({
            columns: [val?.type === 'pickup' ? 'pickup_date' : 'dropoff_date'],
            operation: '<=',
            value: `${moment(val?.date).format('YYYY-MM-DD')}T11:59:59.000Z`,
          });
        }
      }
    },
    [(statusFilter, searchFilter, rangeStartFilter, rangeEndFilter)]
  );
  const fetchOrderFn = (filters) => {
    setLoading(true);
    fetchAllOrders(filters).then((res) => {
      setPaginationData({
        current_page: res?.current_page,
        total_page_count: res?.total_page_count,
        page_size: res?.page_size,
        total_count: res?.total_count,
        count: res?.count,
      });
      setResponse(res?.data || []);
      setLoading(false);
    });
  };
  useEffect(() => {
    if (statusFilter || searchFilter || rangeStartFilter || rangeEndFilter)
      setFilters({
        ...filters,
        filter: [statusFilter, searchFilter, rangeStartFilter, rangeEndFilter].filter(
          (item) => item !== undefined && item?.val !== 'empty' && item !== null
        ),
      });
  }, [statusFilter, searchFilter, rangeStartFilter, rangeEndFilter]);

  useEffect(() => {
    filters && fetchOrderFn(filters);
  }, [filters]);

  return (
    <div className='w-full'>
      <div className='grid grid-cols-2 gap-2 bg-white px-4 pb-4 sm:grid-cols-3 sm:gap-4 sm:px-6'>
        <div className='col-span-full py-1 sm:col-span-1 '>
          <SearchInput placeholder='Search for order' onchange={(val) => onFiltersChange('search', val)} />
        </div>
        <div className='py-1'>
          <DropdownSingle
            dropdownValues={statusDropdown}
            onchange={(val) => {
              onFiltersChange('status', val);
            }}
            prefix='Status - '
          />
        </div>
        <div className='mt-1 py-1'>
          <OrderFiltersPopover onFiltersChange={onFiltersChange} />
        </div>
      </div>
      <div className='mx-auto p-4 sm:p-6'>
        <div className='space-y-6'>
          <div className='space-y-2'>
            {!loading ? (
              !isEmpty(response) ? (
                response?.map((item, idx) => {
                  return (
                    <div key={idx}>
                      <SingleOrder order={item && item} tipModalOpen={tipModalFn} ratingModalOpen={ratingModalFn} />
                    </div>
                  );
                })
              ) : isEmpty(filters?.filter) ? (
                <NoOrders
                  text={`You haven't placed an order yet. Your laundry must be piling up! Let us help you take a load off.`}
                />
              ) : (
                <NoOrders />
              )
            ) : (
              <Loader classes='h-96' text='fetching orders' />
            )}
          </div>
          {paginationData && paginationData?.total_count > 10 && (
            <PaginationNumeric
              data={paginationData}
              onPaginationChange={(page) => {
                setFilters({
                  ...filters,
                  page: page,
                });
              }}
              onPaginationNext={() => {
                setFilters({
                  ...filters,
                  page: filters.page + 1,
                });
              }}
              onPaginationPrev={() => {
                setFilters({
                  ...filters,
                  page: filters.page - 1,
                });
              }}
            />
          )}
        </div>
        <TipModal
          tipModal={tipModal}
          setTipModal={setTipModal}
          orderSelected={orderSelected}
          triggerRefresh={() => fetchOrderFn(filters)}
        />
        <RatingModal
          ratingModal={ratingModal}
          setRatingModal={setRatingModal}
          orderSelected={orderSelected}
          triggerRefresh={() => fetchOrderFn(filters)}
        />
      </div>
    </div>
  );
}
