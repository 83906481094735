import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderPreferencesApi } from '../../../redux/Reducers/MyAccountReducer';
import Cards from './Cards';
import SelectProviders from './SelectProviders';
import OrderPreferences from './OrderPreferences';
import OrderPreferencesModal from './OrderPreferencesModal';

export default function Settings() {
  const dispatch = useDispatch();
  const { allServiceAddresses, selectedAddress } = useSelector((state) => state.serviceAddresses);
  const [providersModal, setProvidersModal] = useState(false);
  const [orderPreference, setOrderPreference] = useState(false);
  useEffect(() => {
    dispatch(getOrderPreferencesApi(selectedAddress?.id));
  }, [dispatch, selectedAddress]);
  return (
    <div className='px-4 sm:px-6'>
      <div className='grid-col-1 grid gap-6 sm:grid-cols-3'>
        <Cards
          providersModal={providersModal}
          setProvidersModal={setProvidersModal}
          setOrderPreference={setOrderPreference}
        />
      </div>
      {/* {orderPreference && <OrderPreferences setOrderPreference={setOrderPreference} />} */}
      <SelectProviders providersModal={providersModal} setProvidersModal={setProvidersModal} />
      <OrderPreferencesModal modalOpen={orderPreference} setModalOpen={setOrderPreference} />
    </div>
  );
}
