import React from 'react';
import homeActive from '../../src/icons/menu/home-active.svg';
import homeInactive from '../../src/icons/menu/home-inactive.svg';
import orderActive from '../../src/icons/menu/orders-active.svg';
import orderInactive from '../../src/icons/menu/orders-inactive.svg';
import accountActive from '../../src/icons/menu/account-active.svg';
import accountInactive from '../../src/icons/menu/account-inactive.svg';
import menuActive from '../../src/icons/menu/menu-active.svg';
import menuInactive from '../../src/icons/menu/menu-inactive.svg';
import promoActive from '../../src/icons/menu/promo-active.svg';
import promoInactive from '../../src/icons/menu/promo-inactive.svg';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CreateOrderSVG from '../components/Icons/CreateOrder';
export default function Footer() {
  const { profileData } = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const menuItems = [
    { name: 'Account', href: '/account', active: accountActive, inactive: accountInactive },
    { name: 'My Orders', href: '/my-orders', active: orderActive, inactive: orderInactive },
    { name: 'Home', href: '/', active: homeActive, inactive: homeInactive },
    { name: 'Promotions', href: '/promotions', active: promoActive, inactive: promoInactive },
    { name: 'Services', href: '/services-and-pricing', active: menuActive, inactive: menuInactive },
  ];
  return (
    <footer className=''>
      {pathname !== '/create-new-order' && (
        <div className='fixed bottom-10 left-0 z-50  w-full px-2 pb-8 sm:hidden'>
          <button
            className='bg-client-primarylight flex w-full items-center justify-center rounded p-3 text-base font-medium text-white shadow-lg hover:opacity-90 focus-visible:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed  '
            onClick={() => navigate('/create-new-order')}
            disabled={profileData?.status != 'ACTIVE' || !profileData?.is_allowed_to_create_order}
          >
            {/* <PlusIcon className='mr-2 h-6 w-6' /> */}
            <CreateOrderSVG />
            Create Order
          </button>
        </div>
      )}

      <div className='fixed bottom-0 left-0 z-50  w-full bg-black pb-2 pt-2 sm:hidden'>
        <div className='relative mx-auto grid h-full max-w-lg grid-cols-5 pb-2 font-medium'>
          {menuItems.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className='group inline-flex flex-col items-center justify-center px-1 '
            >
              <div className='flex h-10 w-14 items-center justify-center bg-black align-middle'>
                <img src={pathname == item.href ? item.active : item.inactive} alt={item.name} className='h-5 w-5' />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </footer>
  );
}
