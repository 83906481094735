//title case and remove special characters
const titleCaseFormatter = (title) => {
  var splitStr = title
    ?.toLowerCase()
    .replace(/[^a-zA-Z ]/g, ' ')
    .split(' ');
  for (var i = 0; i < splitStr?.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  if (splitStr[0] == 'Pickup' && splitStr[1] == 'Complete') {
    splitStr[0] = 'Picked';
    splitStr[1] = 'Up';
  }
  return splitStr?.join(' ');
};
//convert upper case to lower case and relace space with dash
const slugFormatter = (title) => {
  return title?.toLowerCase().replace(/\s+/g, '_');
};

export { titleCaseFormatter, slugFormatter };
