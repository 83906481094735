import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import ClientButton from '../../../components/ClientButton';
import { setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';
import { dateFn } from '../../../utils/dateTimeFormatter';
import { titleCaseFormatter } from '../../../utils/stringFormatters';
import no_picture from '../../../images/no-profile-picture.png';
import { textCheckFn } from '../../../utils/commonUtil';
import { generateInitials } from '../../../components/Utils';
export default function Collapse() {
  const dispatch = useDispatch();
  const {
    orderType,
    orderTimeSlots,
    orderServiceAddress,
    orderProvider,
    orderPreferences,
    orderRepeatFrequency,
    final_tip,
    orderPaymentDetails,
  } = useSelector((state) => state.createNewOrder);
  const [customTipError, setCustomTipError] = useState(false);
  const [tipAmount, setTipAmount] = useState(final_tip ? final_tip : null);
  const onCustomTipChangeFn = (val) => {
    if (val === '') {
      setCustomTipError(false);
      setTipAmount(null);
      return;
    } else if (val == '0') {
      setCustomTipError(false);
      setTipAmount(val);
      return;
    } else if (val < 1) {
      setCustomTipError(true);
      setTipAmount(null);
      return;
    } else if (val > 500) {
      setCustomTipError(true);
      setTipAmount(null);
      return;
    }
    setTipAmount(val);
    setCustomTipError(false);
  };
  const onSaveTipClickFn = () => {
    dispatch(setOrderDataAction({ final_tip: +tipAmount }));
  };
  return (
    <>
      <div className='z-0 col-span-full flex flex-col xl:col-span-7'>
        <div className='w-full rounded-lg bg-white '>
          <div className='mx-auto w-full'>
            <div className='w-full rounded-lg bg-white p-4 px-8'>
              <div className='flex justify-between'>
                <div className='mt-3 w-1/2 flex-row'>
                  <span className='go-label-16'>Service Address </span>
                  {/* <a href='#' className='go-textblack-18 ml-2'>
                  {orderServiceAddress?.name}
                </a> */}
                  <div className='mt-2 w-3/5'>
                    <p className=' go-textblack-16'>{orderServiceAddress?.full_address}</p>
                  </div>
                </div>
                <div className='ml-5 flex w-1/2'>
                  {orderProvider && (
                    <div className='w-full'>
                      <div className='px-4 pt-2'>
                        <p className='go-label-16 py-0.5'>Requested Provider</p>
                        <div className={'mt-2 flex w-full'}>
                          {orderProvider?.id == null ? (
                            <img
                              className={' mb-4 h-[60px] w-[60px] rounded-full bg-[#f3f8ff]'}
                              src={no_picture}
                              alt={'Any Provider'}
                            />
                          ) : orderProvider?.profile_image ? (
                            <img
                              className={' h-[60px] w-[60px] rounded-full '}
                              src={
                                orderProvider?.profile_image ? orderProvider?.profile_image?.absolute_url : no_picture
                              }
                              alt={orderProvider?.first_name}
                            />
                          ) : (
                            <div className='mb-4 h-[60px] w-[60px] rounded-full bg-[#FD56AD] p-2'>
                              <span className='flex h-full w-full items-center justify-center text-3xl font-bold text-white'>
                                {generateInitials(orderProvider?.first_name + ' ' + orderProvider?.last_name)}
                              </span>
                            </div>
                          )}
                          {orderProvider?.id == null ? (
                            <p className='go-textblack-16 py-0.5 pl-3'>Auto-Match Me</p>
                          ) : (
                            <p className='go-textblack-16 py-0.5 pl-3'>
                              {orderProvider?.first_name} {orderProvider?.last_name}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <hr className='go-hr-dashed my-5'></hr>
              <div className='flex'>
                <div>
                  <p className='go-label-16 py-0.5'>Payment Method</p>
                  {orderPaymentDetails?.billing_details?.default_payment_method_type == 'STRIPE' ? (
                    <p className='go-textblack-16 py-0.5'>
                      {titleCaseFormatter(orderPaymentDetails?.billing_details?.default_payment_method?.card_brand)}{' '}
                      **** {orderPaymentDetails?.billing_details?.default_payment_method?.card_last4}
                    </p>
                  ) : orderPaymentDetails?.billing_details?.default_payment_method_type == 'ACH' ? (
                    <p className='go-textblack-16 py-0.5'>
                      {textCheckFn(orderPaymentDetails?.billing_details?.default_payment_method_type)}
                    </p>
                  ) : orderPaymentDetails?.billing_details?.default_payment_method_type == 'CHECK' ? (
                    <p className='go-textblack-16 py-0.5'>
                      {textCheckFn(orderPaymentDetails?.billing_details?.default_payment_method_type)}
                    </p>
                  ) : (
                    <p className='go-textblack-16 py-0.5'>Couldn't load payment details.</p>
                  )}
                </div>
              </div>
              <hr className='go-hr-dashed my-5'></hr>
              {orderType === 'subscription' && (
                <div className='my-3 flex'>
                  <span className='go-label-16'>Repeat every: </span>
                  <span className='go-textblacknormal-16 ml-2'>{orderRepeatFrequency?.value}</span>
                </div>
              )}
              <div className='my-3 flex flex-col sm:flex-row'>
                <span className='go-label-16 mr-2'>Pickup date: </span>
                {orderType === 'oneTime' ? (
                  <span className='go-textblacknormal-16'>
                    {dateFn(
                      'toLocal',
                      orderTimeSlots?.pickup_date,
                      orderTimeSlots?.pickup_start_time,
                      orderTimeSlots?.pickup_end_time,
                      orderServiceAddress?.time_zone
                    )}
                  </span>
                ) : (
                  <span className='go-textblacknormal-16'>
                    {dateFn(
                      'toLocal',
                      orderTimeSlots?.first_pickup_date,
                      orderTimeSlots?.pickup_start_time,
                      orderTimeSlots?.pickup_end_time,
                      orderServiceAddress?.time_zone
                    )}
                  </span>
                )}
              </div>
              {/* <div className='my-3 flex'>
                      <span className='go-label-16'>Pickup time: </span>
                      <span className='go-textblacknormal-16 ml-2'>{orderTimeSlots?.pickup_times?.value}</span>
                    </div> */}
              <div className='my-3 flex flex-col sm:flex-row'>
                <span className='go-label-16 mr-2'>Delivery date: </span>
                <span className='go-textblacknormal-16'>
                  {dateFn(
                    'toLocal',
                    orderTimeSlots?.dropoff_date,
                    orderTimeSlots?.dropoff_start_time,
                    orderTimeSlots?.dropoff_end_time,
                    orderServiceAddress?.time_zone
                  )}
                </span>
              </div>

              <hr className='go-hr-dashed my-5'></hr>
              <div className='my-3 flex'>
                <span className='go-label-16'>Preferences: </span>

                <span className='go-textblacknormal-16 ml-2'>{orderPreferences}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-4 w-full rounded-lg bg-white p-4 px-8'>
          <div className='mx-auto w-full'>
            <p className='go-textblack-18'>Add a tip for your service provider</p>
            <div className='mt-5'>
              <label className='go-label-16 mb-2 block' htmlFor='tipInput'>
                {orderProvider?.first_name
                  ? `Tip for ${orderProvider?.first_name} ${orderProvider?.last_name} `
                  : 'Tip for provider'}
              </label>
              <input
                id='tipInput'
                className={`go-inputtext-16 w-full sm:w-80 ${customTipError ? 'border-red-500' : ''}`}
                type='number'
                placeholder=''
                defaultValue={tipAmount}
                onChange={(e) => onCustomTipChangeFn(e.target.value)}
              />
              {customTipError && (
                <p className='go-textnormal-14 text-red-500'>Minimum tip amount should be between 1$ - 500$. </p>
              )}
            </div>
            <div className='flex justify-center sm:justify-start'>
              <ClientButton
                size='small'
                variant='primary'
                text='Save'
                classes='mt-2 w-40'
                disabled={tipAmount === null}
                onClick={() => {
                  onSaveTipClickFn();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
