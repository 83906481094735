import React, { useCallback, useEffect, useState } from 'react';
import { selectedServices } from '../../../mockdata/common';
import OrderInfo from './OrderInfo';
import Services from './Services';
import { tipAmount } from '../../../mockdata/myOrder';
import TipModal from '../../../components/TipModal';
import { useParams } from 'react-router-dom';
import DeliveredStatus from './DeliveredStatus';
import ClientButton from '../../../components/ClientButton';
import CancelModal from './CancalModal';
import { getOrderDetailsApi } from '../../../redux/Reducers/MyOrdersReducer';
import NoData from '../../../components/NoData';
import Loader from '../../../components/Loader';
import OrderStatusBar from './OrderStatusBar';
import { toast } from 'react-hot-toast';
import { updateOrderApi } from '../../../redux/Reducers/MyAccountReducer';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { resetCreateOrderFlow } from '../../../redux/Reducers/CreateOrderReducer';
import Button from '../../../components/Button';
export default function OrderView() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [newOrderDetails, setNewOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [edit, setEdit] = useState({
    orderPickupDeliveryEdit: false,
    orderServicesEdit: false,
  });
  const [cancelModal, setCancelModal] = useState(false);

  const [tipModal, setTipModal] = useState(false);
  const cancelModalFn = useCallback((e, val) => {
    e.stopPropagation();
    setCancelModal(val);
  }, []);
  const tipModalFn = useCallback((e, val) => {
    e.stopPropagation();
    setTipModal(val);
  }, []);

  const getOrderDetailsFn = (orderId) => {
    setLoading(true);
    const response = getOrderDetailsApi(orderId);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setOrderDetails(res?.data);
        setCurrentStatus(res?.data?.status || null);
        setLoading(false);
        // dispatch(setOrderDataAction({ availableSchedules: res?.data || [] }));
      } else {
        setLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  const onOrderUpdateFn = (id) => {
    let newItems = newOrderDetails?.items?.map((item) => {
      if (item?.operation === 'EXISTING') {
        return { ...item, operation: 'UPDATE' };
      }
      return item;
    });
    let isSame = false;
    if (orderDetails?.items?.length == newOrderDetails?.items?.length) {
      orderDetails?.items?.some((item) => {
        if (item?.quantity == newOrderDetails?.items?.find((i) => i?.id == item?.id)?.quantity) {
          isSame = true;
        } else {
          isSame = false;
        }
        let temp = newOrderDetails?.items?.find((i) => i?.operation == 'DELETE');
        if (temp) {
          isSame = false;
        }
      });
    }

    const payload = {
      pickup_date: newOrderDetails?.pickup_date ? newOrderDetails?.pickup_date : undefined,
      pickup_start_time: newOrderDetails?.pickup_start_time ? newOrderDetails?.pickup_start_time : undefined,
      pickup_end_time: newOrderDetails?.pickup_end_time ? newOrderDetails?.pickup_end_time : undefined,
      dropoff_date: newOrderDetails?.dropoff_date ? newOrderDetails?.dropoff_date : undefined,
      dropoff_start_time: newOrderDetails?.dropoff_start_time ? newOrderDetails?.dropoff_start_time : undefined,
      dropoff_end_time: newOrderDetails?.dropoff_end_time ? newOrderDetails?.dropoff_end_time : undefined,
      items: !isSame ? newItems : undefined,
      preferences:
        newOrderDetails?.preferences !== orderDetails?.preferences ? newOrderDetails?.preferences : undefined,
    };
    // for (var k in newOrderDetails) {
    //   if (orderDetails[k] != newOrderDetails[k]) {
    //     result[k] = newOrderDetails[k];
    //   }
    // }

    // const payload = { ...result };
    // if (payload?.items) {
    //   result.items = payload.items.filter((item) => item?.operation != 'EXISTING');
    // }
    if (isEmpty(payload)) {
      toast.error('No changes made');
      return;
    } else {
      const response = updateOrderApi(id, payload);
      response?.then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          getOrderDetailsFn(id);
          setEdit(false);
          toast.success(res?.message || 'Order updated successfully');
        } else {
          toast.error(res?.message || 'Something went wrong');
        }
      });
    }
  };

  useEffect(() => {
    getOrderDetailsFn(id);
    dispatch(resetCreateOrderFlow());
  }, [id]);
  useEffect(() => {
    setCurrentStatus(id);
  }, [location]);

  return (
    <>
      {loading ? (
        <Loader text='loading order details...' classes='h-96' />
      ) : !orderDetails ? (
        <NoData text='no order found' classes='h-96' />
      ) : (
        <div className='px-4 sm:px-6'>
          <div className='max-w-9xl mx-auto w-full py-3'>
            <OrderStatusBar orderDetails={orderDetails} />
            {currentStatus === 'DELIVERED' && (
              <p className='go-blue-18 mb-5'>Please rate your order and leave feedback.</p>
            )}
            <div className='grid grid-cols-12 gap-4 sm:gap-6'>
              {currentStatus === 'DELIVERED' && (
                <DeliveredStatus tipModalOpen={tipModalFn} orderDetails={orderDetails} />
              )}
              {currentStatus !== 'DELIVERED' && (
                <OrderInfo
                  orderDetails={orderDetails}
                  tipModalOpen={tipModalFn}
                  status={currentStatus}
                  edit={edit}
                  setEdit={setEdit}
                  newOrderDetails={newOrderDetails}
                  setNewOrderDetails={setNewOrderDetails}
                />
              )}
              <Services
                orderDetails={orderDetails}
                edit={edit}
                setEdit={setEdit}
                status={currentStatus}
                newOrderDetails={newOrderDetails}
                setNewOrderDetails={setNewOrderDetails}
              />
              <TipModal
                tipModal={tipModal}
                setTipModal={setTipModal}
                orderSelected={orderDetails}
                triggerRefresh={() => getOrderDetailsFn(orderDetails?.id)}
              />
            </div>
          </div>
          {currentStatus === 'WAITING' && (
            <div className='relative top-2'>
              <div className='flex-row justify-between sm:flex'>
                <div>
                  <ClientButton
                    variant='secondary'
                    text='Back'
                    classes='w-full sm:w-auto'
                    onClick={() => window.history.back()}
                  />
                  {(edit.orderPickupDeliveryEdit || edit.orderServicesEdit) && (
                    <ClientButton
                      variant='primary'
                      text='Save'
                      classes='w-full sm:w-auto'
                      onClick={() => onOrderUpdateFn(orderDetails?.id)}
                    />
                  )}
                </div>
                <div>
                  <ClientButton
                    variant='cancel'
                    text='Cancel Order'
                    classes='w-full sm:w-auto'
                    onClick={(e) => {
                      cancelModalFn(e, true);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {currentStatus === 'CANCELLED' && (
            <div className='relative mb-10'>
              <div className='flex-row justify-between sm:flex'>
                <Button
                  variant='secondary'
                  text='Back'
                  classes='w-full sm:w-56'
                  onClick={() => window.history.back()}
                />
              </div>
            </div>
          )}
          <CancelModal cancelModal={cancelModal} setCancelModal={setCancelModal} orderId={orderDetails?.id} />
        </div>
      )}
    </>
  );
}
